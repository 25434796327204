var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from './classNames';
/**
 * @hidden
 */

var FloatingLabel =
/** @class */
function (_super) {
  __extends(FloatingLabel, _super);

  function FloatingLabel() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      focused: false
    };

    _this.handleFocus = function (_) {
      _this.setState({
        focused: true
      });
    };

    _this.handleBlur = function (_) {
      _this.setState({
        focused: false
      });
    };

    return _this;
  }

  FloatingLabel.prototype.render = function () {
    var _a = this.props,
        label = _a.label,
        id = _a.id,
        className = _a.className,
        value = _a.value,
        placeholder = _a.placeholder,
        valid = _a.valid,
        style = _a.style;
    var childClassNames = classNames({
      'k-textbox-container': true,
      'k-state-focused': this.state.focused,
      'k-state-empty': !(value || placeholder),
      'k-state-invalid': !valid && valid !== undefined
    }, className);
    return React.createElement("span", {
      className: childClassNames,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      style: style
    }, this.props.children, label ? id ? React.createElement("label", {
      htmlFor: id,
      className: "k-label"
    }, label) : React.createElement("span", {
      className: "k-label"
    }, label) : null);
  };

  FloatingLabel.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    valid: PropTypes.bool
  };
  return FloatingLabel;
}(React.Component);

export default FloatingLabel;