var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import util from './util';
var styles = util.styles;

var ButtonGroup =
/** @class */
function (_super) {
  __extends(ButtonGroup, _super);

  function ButtonGroup() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this._element = null;
    return _this;
  }
  /**
   * @hidden
   */


  ButtonGroup.prototype.render = function () {
    var _this = this;

    var _a;

    var buttons = this.mapButtons(this.props.children);
    var groupClasses = classNames([styles['button-group']], (_a = {}, _a[styles['state-disabled']] = this.props.disabled, _a[styles['button-group-stretched']] = !!this.props.width, _a), this.props.className);
    var groupProps = {
      className: groupClasses,
      style: {
        'width': "" + this.props.width
      },
      dir: this.props.dir,
      // Accessibility properties
      role: 'group',
      'aria-disabled': this.props.disabled,
      'aria-multiselectable': true
    };
    return React.createElement("div", __assign({
      ref: function ref(div) {
        _this._element = div;
      }
    }, groupProps, {
      className: groupClasses
    }), buttons);
  };

  ButtonGroup.prototype.mapButtons = function (children) {
    var _this = this;

    var count = React.Children.count(children);
    var rtl = this.props.dir !== undefined ? this.props.dir === 'rtl' : this._element && getComputedStyle(this._element).direction === 'rtl' || false;
    return React.Children.map(children, function (child, index) {
      if (React.isValidElement(child)) {
        return _this.renderButton(child, index, index === count - 1, rtl);
      }

      return child;
    });
  };

  ButtonGroup.prototype.renderButton = function (child, index, isLast, isRtl) {
    var _a;

    var className = classNames(child.props.className, (_a = {}, _a[styles['group-start']] = isRtl ? isLast : index === 0, _a[styles['group-end']] = isRtl ? index === 0 : isLast, _a));

    var style = __assign({}, this.props.width ? {
      width: this.props.width
    } : {}, child.props.style || {});

    var disabled = this.props.disabled || child.props.disabled;

    var buttonProps = __assign({}, child.props, className ? {
      className: className
    } : {}, Object.keys(style).length ? {
      style: style
    } : {}, disabled !== undefined ? {
      disabled: disabled
    } : {});

    return React.Children.count(child.props.children) > 0 ? React.cloneElement(child, buttonProps, child.props.children) : React.cloneElement(child, buttonProps);
  };
  /**
   * @hidden
   */


  ButtonGroup.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    dir: PropTypes.string
  };
  return ButtonGroup;
}(React.Component);

export default ButtonGroup;