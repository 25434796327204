var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IntlService } from './IntlService'; // tslint:disable:max-line-length

/**
 * A React component which provides an internationalization service. Expects a locale string as a property of the component.
 */

var IntlProvider =
/** @class */
function (_super) {
  __extends(IntlProvider, _super);

  function IntlProvider() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Returns an internationalization service. The method is suitable for overriding when you implement custom internationalization behavior.
   */


  IntlProvider.prototype.getIntlService = function () {
    return new IntlService(this.props.locale);
  };
  /**
   * @hidden
   */


  IntlProvider.prototype.getChildContext = function () {
    return {
      kendoIntlService: this.getIntlService()
    };
  };
  /**
   * @hidden
   */


  IntlProvider.prototype.render = function () {
    return React.Children.only(this.props.children);
  };
  /**
   * @hidden
   */


  IntlProvider.propTypes = {
    locale: PropTypes.string
  };
  /**
   * @hidden
   */

  IntlProvider.childContextTypes = {
    kendoIntlService: PropTypes.object
  };
  return IntlProvider;
}(React.Component);

export default IntlProvider; // tslint:enable:max-line-length