var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { LocalizationService } from './LocalizationService'; // tslint:disable:max-line-length

/**
 * A React component which provides a localization service. Expects a language string as a property of the component.
 */

var LocalizationProvider =
/** @class */
function (_super) {
  __extends(LocalizationProvider, _super);

  function LocalizationProvider() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * Returns a localization service. The method is suitable for overriding when you implement custom localization behavior.
   */


  LocalizationProvider.prototype.getLocalizationService = function () {
    return new LocalizationService(this.props.language);
  };
  /**
   * @hidden
   */


  LocalizationProvider.prototype.getChildContext = function () {
    return {
      kendoLocalizationService: this.getLocalizationService()
    };
  };
  /**
   * @hidden
   */


  LocalizationProvider.prototype.render = function () {
    return React.Children.only(this.props.children);
  };
  /**
   * @hidden
   */


  LocalizationProvider.propTypes = {
    language: PropTypes.string
  };
  /**
   * @hidden
   */

  LocalizationProvider.childContextTypes = {
    kendoLocalizationService: PropTypes.object
  };
  return LocalizationProvider;
}(React.Component);

export { LocalizationProvider }; // tslint:enable:max-line-length