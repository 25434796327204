/*
 * Copyright 2015, Yahoo Inc.
 * Copyrights licensed under the New BSD License.
 * See the accompanying LICENSE file for terms.
 */
// Since rollup cannot deal with namespace being a function,
// this is to interop with TypeScript since `invariant`
// does not export a default
// https://github.com/rollup/rollup/issues/1267
import * as invariant_ from 'invariant';
var invariant = invariant_.default || invariant_;
import { createError, escape } from '../utils';
import { TYPE } from 'intl-messageformat-parser';
/**
 * Escape a raw msg when we run in prod mode
 * https://github.com/formatjs/formatjs/blob/master/packages/intl-messageformat-parser/src/parser.pegjs#L155
 */

function escapeUnformattedMessage(msg) {
  return msg.replace(/'\{(.*?)\}'/g, "{$1}");
}

export function formatMessage(_ref, state) {
  var locale = _ref.locale,
      formats = _ref.formats,
      messages = _ref.messages,
      defaultLocale = _ref.defaultLocale,
      defaultFormats = _ref.defaultFormats,
      onError = _ref.onError;
  var messageDescriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    id: ''
  };
  var values = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var id = messageDescriptor.id,
      defaultMessage = messageDescriptor.defaultMessage; // `id` is a required field of a Message Descriptor.

  invariant(id, '[React Intl] An `id` must be provided to format a message.');
  var message = messages && messages[id];
  var hasValues = Object.keys(values).length > 0; // Avoid expensive message formatting for simple messages without values. In
  // development messages will always be formatted in case of missing values.

  if (!hasValues && process.env.NODE_ENV === 'production') {
    var val = message || defaultMessage || id;

    if (typeof val === 'string') {
      return escapeUnformattedMessage(val);
    }

    invariant(val.length === 1 && val[0].type === TYPE.literal, 'Message has placeholders but no values was provided');
    return val[0].value;
  }

  var formattedMessageParts = [];

  if (message) {
    try {
      var formatter = state.getMessageFormat(message, locale, formats, {
        formatters: state
      });
      formattedMessageParts = formatter.formatHTMLMessage(values);
    } catch (e) {
      onError(createError("Error formatting message: \"".concat(id, "\" for locale: \"").concat(locale, "\"") + (defaultMessage ? ', using default message as fallback.' : ''), e));
    }
  } else {
    // This prevents warnings from littering the console in development
    // when no `messages` are passed into the <IntlProvider> for the
    // default locale, and a default message is in the source.
    if (!defaultMessage || locale && locale.toLowerCase() !== defaultLocale.toLowerCase()) {
      onError(createError("Missing message: \"".concat(id, "\" for locale: \"").concat(locale, "\"") + (defaultMessage ? ', using default message as fallback.' : '')));
    }
  }

  if (!formattedMessageParts.length && defaultMessage) {
    try {
      var _formatter = state.getMessageFormat(defaultMessage, defaultLocale, defaultFormats);

      formattedMessageParts = _formatter.formatHTMLMessage(values);
    } catch (e) {
      onError(createError("Error formatting the default message for: \"".concat(id, "\""), e));
    }
  }

  if (!formattedMessageParts.length) {
    onError(createError("Cannot format message: \"".concat(id, "\", ") + "using message ".concat(message || defaultMessage ? 'source' : 'id', " as fallback.")));

    if (typeof message === 'string') {
      return message || defaultMessage || id;
    }

    return defaultMessage || id;
  }

  if (formattedMessageParts.length === 1 && typeof formattedMessageParts[0] === 'string') {
    return formattedMessageParts[0] || defaultMessage || id;
  }

  return formattedMessageParts;
}
export function formatHTMLMessage(config, state) {
  var messageDescriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    id: ''
  };
  var rawValues = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  // Process all the values before they are used when formatting the ICU
  // Message string. Since the formatted message might be injected via
  // `innerHTML`, all String-based values need to be HTML-escaped.
  var escapedValues = Object.keys(rawValues).reduce(function (escaped, name) {
    var value = rawValues[name];
    escaped[name] = typeof value === 'string' ? escape(value) : value;
    return escaped;
  }, {});
  return formatMessage(config, state, messageDescriptor, escapedValues);
}