var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, guid, Keys } from '@progress/kendo-react-common';
import { cloneDate, getDate, isEqualDate } from '@progress/kendo-date-math';
import { provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { ViewList } from './ViewList';
import { Navigation } from './Navigation';
import { MIN_DATE, MAX_DATE } from '../../defaults';
import { CalendarViewEnum } from '../models';
import { getToday, dateInRange, isInRange, viewInRange } from '../../utils';
import { BusViewService, DOMService, ScrollSyncService, NavigationService } from '../services';

var virtualizationProp = function virtualizationProp(x) {
  return x ? x.virtualization : null;
};

var calculateValue = function calculateValue(min, max, stateValue, propValue) {
  if (min === void 0) {
    min = Calendar.defaultProps.min;
  }

  if (max === void 0) {
    max = Calendar.defaultProps.max;
  }

  return propValue !== undefined ? propValue !== null && isInRange(propValue, min, max) ? propValue : null : stateValue !== null && isInRange(stateValue, min, max) ? stateValue : null;
};

var Calendar =
/** @class */
function (_super) {
  __extends(Calendar, _super);

  function Calendar(props) {
    var _this = _super.call(this, props) || this;

    _this.cellUID = guid();
    _this.id = guid();
    _this.isActive = false;

    _this.handleScroll = function (event) {
      if (!_this.scrollSyncService) {
        return;
      }

      _this.scrollSyncService.sync(virtualizationProp(_this.Navigation), virtualizationProp(_this.calendarViewList), event);
    };

    _this.handleNavigationChange = function (event) {
      if (_this.props.disabled) {
        return;
      }

      var focusedDate = cloneDate(event.value);

      _this.setState({
        focusedDate: focusedDate
      });
    };

    _this.handleViewChange = function (_a) {
      var view = _a.view;

      _this.scrollSyncService.configure(view);

      _this.setState({
        activeView: view
      });
    };

    _this.handleDateChange = function (event) {
      var value = cloneDate(event.value);
      var focusedDate = cloneDate(event.value);

      var canNavigateDown = _this.bus.canMoveDown(_this.state.activeView);

      if (_this.props.disabled) {
        return;
      }

      if (canNavigateDown) {
        if (event.isTodayClick) {
          _this.bus.moveToBottom(_this.state.activeView);
        } else {
          _this.bus.moveDown(_this.state.activeView, event.syntheticEvent);

          _this.setState({
            focusedDate: focusedDate
          });

          return;
        }
      }

      _this.setState({
        value: value,
        focusedDate: focusedDate
      });

      _this.valueDuringOnChange = value;
      var onChange = _this.props.onChange;

      if (onChange) {
        var args = {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: value,
          target: _this
        };
        onChange.call(undefined, args);
      }

      _this.valueDuringOnChange = undefined;
    };

    _this.handleFocus = function (event) {
      _this.isActive = true;

      if (!_this.calendarViewList) {
        return;
      }

      _this.calendarViewList.focusActiveDate();

      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      _this.isActive = false;

      if (!_this.calendarViewList) {
        return;
      }

      _this.calendarViewList.blurActiveDate();

      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handleKeyDown = function (event) {
      var keyCode = event.keyCode;

      if (keyCode === Keys.enter) {
        if (_this.value !== null && isEqualDate(_this.focusedDate, _this.value)) {
          var viewDate = dateInRange(_this.focusedDate, _this.min, _this.max);
          virtualizationProp(_this.calendarViewList).scrollToIndex(_this.service.skip(viewDate, _this.min));
        }

        var args = {
          syntheticEvent: event,
          nativeEvent: event.nativeEvent,
          value: _this.focusedDate,
          target: _this
        };

        _this.handleDateChange.call(undefined, args);
      } else {
        var candidate = dateInRange(_this.navigation.move(_this.focusedDate, _this.navigation.action(event), _this.state.activeView, _this.service, event), _this.min, _this.max);

        if (isEqualDate(_this.focusedDate, candidate)) {
          return;
        }

        _this.setState({
          focusedDate: candidate
        });
      }

      event.preventDefault();
    };

    _this.handleMouseDown = function (event) {
      event.preventDefault();
    };

    _this.handleClick = function (_) {
      if (_this._element) {
        _this._element.focus({
          preventScroll: true
        });
      }
    };

    var value = calculateValue(_this.min, _this.max, _this.props.defaultValue || Calendar.defaultProps.defaultValue, _this.props.value);
    _this.state = {
      value: value,
      activeView: viewInRange(CalendarViewEnum[props.defaultActiveView], _this.bottomView, _this.topView),
      focusedDate: dateInRange(props.focusedDate || value || getToday(), _this.min, _this.max)
    };
    _this.dom = new DOMService();
    _this.bus = new BusViewService(_this.handleViewChange);
    _this.navigation = new NavigationService(_this.bus);
    _this.oldValue = value;
    return _this;
  }

  Object.defineProperty(Calendar.prototype, "element", {
    /**
     * Gets the wrapping element of the Calendar.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Calendar.prototype, "value", {
    /**
     * Gets the value of the Calendar.
     */
    get: function get() {
      return this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Calendar.prototype, "min", {
    get: function get() {
      return getDate(this.props.min !== undefined ? this.props.min : Calendar.defaultProps.min);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Calendar.prototype, "max", {
    get: function get() {
      return getDate(this.props.max !== undefined ? this.props.max : Calendar.defaultProps.max);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Calendar.prototype, "bottomView", {
    get: function get() {
      return CalendarViewEnum[this.props.bottomView !== undefined ? this.props.bottomView : Calendar.defaultProps.bottomView];
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Calendar.prototype, "topView", {
    get: function get() {
      return CalendarViewEnum[this.props.topView !== undefined ? this.props.topView : Calendar.defaultProps.topView];
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  Calendar.prototype.componentDidMount = function () {
    var _this = this; // Async calculation of height to avoid animation cancellation


    Promise.resolve().then(function () {
      if (!_this._element) {
        return;
      }

      _this.dom.calculateHeights(_this._element);

      _this.scrollSyncService = new ScrollSyncService(_this.dom);

      _this.scrollSyncService.configure(_this.state.activeView);

      _this.forceUpdate();
    });
  };
  /**
   * @hidden
   */


  Calendar.prototype.componentDidUpdate = function (_, prevState) {
    if (prevState.activeView !== this.state.activeView) {
      this.scrollSyncService.configure(this.state.activeView);
    }

    if (this.calendarViewList) {
      this.isActive ? this.calendarViewList.focusActiveDate() : this.calendarViewList.blurActiveDate();
    }

    this.oldValue = this.value;
  };
  /**
   * @hidden
   */


  Calendar.prototype.render = function () {
    var _this = this;

    var didValueChange = this.value !== null && this.oldValue !== null ? !isEqualDate(this.value, this.oldValue) : this.value !== this.oldValue;
    var activeView = viewInRange(this.state.activeView, CalendarViewEnum[this.props.bottomView !== undefined ? this.props.bottomView : Calendar.defaultProps.bottomView], CalendarViewEnum[this.props.topView !== undefined ? this.props.topView : Calendar.defaultProps.topView]);
    var value = calculateValue(this.min, this.max, this.value, this.value);
    var sanitizedValue = value ? getDate(value) : null;
    this.focusedDate = getDate(dateInRange(didValueChange && value !== null ? value : this.state.focusedDate, this.min, this.max));
    this.intl = provideIntlService(this);
    this.bus.configure(this.bottomView, this.topView);
    this.service = this.bus.service(activeView, this.intl);
    var rootClassNames = classNames('k-widget k-calendar k-calendar-infinite', {
      'k-state-disabled': this.props.disabled,
      'k-week-number': this.props.weekNumber
    }, this.props.className);
    var aria = {
      'aria-disabled': this.props.disabled,
      'aria-activedescendant': this.cellUID + this.focusedDate.getTime()
    };
    var calendarBody = [this.props.navigation && React.createElement(Navigation, {
      key: 0,
      ref: function ref(el) {
        _this.Navigation = el;
      },
      activeView: this.state.activeView,
      focusedDate: this.focusedDate,
      min: this.min,
      max: this.max,
      onChange: this.handleNavigationChange,
      service: this.service,
      dom: this.dom,
      navigationItem: this.props.navigationItem
    }), React.createElement(ViewList, {
      key: 1,
      ref: function ref(el) {
        _this.calendarViewList = el;
      },
      activeView: this.state.activeView,
      focusedDate: this.focusedDate,
      min: this.min,
      max: this.max,
      bus: this.bus,
      service: this.service,
      cell: this.props.cell,
      weekCell: this.props.weekCell,
      dom: this.dom,
      smoothScroll: this.props.smoothScroll,
      showWeekNumbers: this.props.weekNumber,
      onChange: this.handleDateChange,
      value: sanitizedValue,
      cellUID: this.cellUID,
      headerTitle: this.props.headerTitle
    })];
    return React.createElement("div", __assign({}, aria, {
      ref: function ref(el) {
        _this._element = el;
      },
      className: rootClassNames,
      id: this.props.id || this.id,
      role: "grid",
      tabIndex: !this.props.disabled ? this.props.tabIndex || 0 : undefined,
      onScroll: this.handleScroll,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onKeyDown: this.handleKeyDown,
      onMouseDown: this.handleMouseDown,
      onClick: this.handleClick
    }), calendarBody);
  };
  /**
   * @hidden
   */


  Calendar.propTypes = {
    className: PropTypes.string,
    defaultActiveView: PropTypes.oneOf(['month', 'year', 'decade', 'century']),
    defaultValue: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    focusedDate: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    navigation: PropTypes.bool,
    smoothScroll: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    tabIndex: PropTypes.number,
    value: PropTypes.instanceOf(Date),
    weekNumber: PropTypes.bool,
    topView: function topView(props, propName, componentName) {
      var prop = props[propName];
      var bottomView = props.bottomView;

      if (prop && bottomView && CalendarViewEnum[prop] < CalendarViewEnum[bottomView]) {
        return new Error("Invalid prop + " + propName + " suplied to " + componentName + ".\n                    " + propName + " can not be smaller than bottomView.\n                    ");
      }
    },
    bottomView: function bottomView(props, propName, componentName) {
      var prop = props[propName];
      var topView = props.topView;

      if (prop && topView && CalendarViewEnum[prop] > CalendarViewEnum[topView]) {
        return new Error("Invalid prop + " + propName + " suplied to " + componentName + ".\n                    " + propName + " can not be bigger than topView.\n                    ");
      }
    }
  };
  /**
   * @hidden
   */

  Calendar.defaultProps = {
    disabled: false,
    min: MIN_DATE,
    max: MAX_DATE,
    navigation: true,
    defaultActiveView: 'month',
    defaultValue: null,
    smoothScroll: true,
    topView: 'century',
    bottomView: 'month'
  };
  return Calendar;
}(React.Component);

export { Calendar };
registerForIntl(Calendar);