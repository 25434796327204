module.exports = {
  // Higher level API, different shaped progress bars
  Line: require('./line'),
  Circle: require('./circle'),
  SemiCircle: require('./semicircle'),
  // Lower level API to use any SVG path
  Path: require('./path'),
  // Base-class for creating new custom shapes
  // to be in line with the API of built-in shapes
  // Undocumented.
  Shape: require('./shape'),
  // Internal utils, undocumented.
  utils: require('./utils')
};