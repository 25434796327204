var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { cloneDate } from '@progress/kendo-date-math';
import { classNames, guid, Keys } from '@progress/kendo-react-common';
import { DateInput } from '../dateinput/DateInput';
import { MIN_DATE, MAX_DATE, isInRangePicker } from '../utils';
import { messages, toggleDateTimeSelector } from '../messages';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { DateTimeSelector } from './DateTimeSelector';

var DateTimePicker =
/** @class */
function (_super) {
  __extends(DateTimePicker, _super);

  function DateTimePicker(props) {
    var _this = _super.call(this, props) || this;

    _this._popupId = guid();
    _this._element = null;
    _this._wrapper = null;
    _this._dateInput = null;
    _this._dateTimeSelector = null;
    _this.shouldFocusDateInput = false;

    _this.handleValueChange = function (event) {
      _this.setState({
        value: cloneDate(event.value || undefined),
        show: false
      });

      _this.valueDuringOnChange = event.value;
      _this.showDuringOnChange = false;
      _this.shouldFocusDateInput = true;
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange.call(undefined, {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: _this.value,
          show: _this.show,
          target: _this
        });
      }

      _this.valueDuringOnChange = undefined;
      _this.showDuringOnChange = undefined;
    };

    _this.handleFocus = function (event) {
      clearTimeout(_this.nextTickId);

      if (_this._wrapper) {
        _this._wrapper.classList.add('k-state-focused');
      }

      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      _this.nextTick(function () {
        if (_this._wrapper) {
          _this._wrapper.classList.remove('k-state-focused');
        }

        _this.setShow(false);
      });

      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handleDateIconClick = function () {
      if (_this.props.disabled) {
        return;
      }

      _this.shouldFocusDateInput = true;

      _this.setShow(!_this.show);
    };

    _this.handleIconMouseDown = function (event) {
      event.preventDefault();
    };

    _this.handleKeyDown = function (event) {
      var altKey = event.altKey,
          keyCode = event.keyCode;

      if (keyCode === Keys.esc) {
        _this.setShow(false);

        return;
      }

      if (altKey && (keyCode === Keys.up || keyCode === Keys.down)) {
        event.preventDefault();
        event.stopPropagation();
        _this.shouldFocusDateInput = keyCode === Keys.up;

        _this.setShow(keyCode === Keys.down);
      }
    };

    _this.state = {
      value: _this.props.defaultValue || DateTimePicker.defaultProps.defaultValue,
      show: _this.props.defaultShow || DateTimePicker.defaultProps.defaultShow
    };
    return _this;
  }

  Object.defineProperty(DateTimePicker.prototype, "element", {
    /**
     * Gets the wrapping element of the DateTimePicker.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "dateInput", {
    /**
     * Gets the DateInput component inside the DateTimePicker component.
     */
    get: function get() {
      return this._dateInput;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "value", {
    /**
     * Gets the value of the DateTimePicker.
     */
    get: function get() {
      var value = this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
      return value !== null ? cloneDate(value) : null;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "show", {
    /**
     * Gets the popup state of the DateTimePicker.
     */
    get: function get() {
      return this.showDuringOnChange !== undefined ? this.showDuringOnChange : this.props.show !== undefined ? this.props.show : this.state.show;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "name", {
    /**
     * Gets the `name` property of the DateTimePicker.
     */
    get: function get() {
      return this.props.name;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "min", {
    get: function get() {
      return this.props.min !== undefined ? this.props.min : DateTimePicker.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "max", {
    get: function get() {
      return this.props.max !== undefined ? this.props.max : DateTimePicker.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "validity", {
    /**
     * Represents the validity state into which the DateTimePicker is set.
     */
    get: function get() {
      var inRange = isInRangePicker(this.value, this.min, this.max);
      var customError = this.props.validationMessage !== undefined;
      var isValid = (!this.required || this.value !== null) && inRange;
      var valid = this.props.valid !== undefined ? this.props.valid : isValid;
      return {
        customError: customError,
        rangeOverflow: this.value && this.max.getTime() < this.value.getTime() || false,
        rangeUnderflow: this.value && this.value.getTime() < this.min.getTime() || false,
        valid: valid,
        valueMissing: this.value === null
      };
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "validityStyles", {
    /**
     * @hidden
     */
    get: function get() {
      return this.props.validityStyles !== undefined ? this.props.validityStyles : DateTimePicker.defaultProps.validityStyles;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimePicker.prototype, "required", {
    /**
     * @hidden
     */
    get: function get() {
      return this.props.required !== undefined ? this.props.required : DateTimePicker.defaultProps.required;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  DateTimePicker.prototype.componentDidMount = function () {
    if (this.show) {
      // If defaultShow is true during the initial render, the popup is not aligned.
      this.forceUpdate();
    }

    if (this._dateInput && this._dateInput.element) {
      this._dateInput.element.setAttribute('aria-haspopup', 'true');

      this._dateInput.element.setAttribute('aria-expanded', "" + this.show);
    }
  };
  /**
   * @hidden
   */


  DateTimePicker.prototype.componentDidUpdate = function (prevProps) {
    if (this._dateInput && this._dateInput.element) {
      this._dateInput.element.setAttribute('aria-expanded', "" + this.show);
    }

    if (this._dateTimeSelector && this.show && !prevProps.show) {
      this._dateTimeSelector.focus({
        preventScroll: true
      });
    }

    if (this._dateInput && this._dateInput.element && !this.show && this.shouldFocusDateInput) {
      this._dateInput.element.focus({
        preventScroll: true
      });
    }

    this.shouldFocusDateInput = false;
  };
  /**
   * @hidden
   */


  DateTimePicker.prototype.componentWillUnmount = function () {
    clearTimeout(this.nextTickId);
  };
  /**
   * @hidden
   */


  DateTimePicker.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        disabled = _a.disabled,
        tabIndex = _a.tabIndex,
        title = _a.title,
        id = _a.id,
        format = _a.format,
        formatPlaceholder = _a.formatPlaceholder,
        min = _a.min,
        max = _a.max,
        weekNumber = _a.weekNumber,
        focusedDate = _a.focusedDate,
        className = _a.className,
        width = _a.width,
        name = _a.name,
        validationMessage = _a.validationMessage,
        required = _a.required,
        validityStyles = _a.validityStyles,
        cancelButton = _a.cancelButton;
    var isValid = !this.validityStyles || this.validity.valid;
    var rootClassName = classNames('k-widget k-datetimepicker', {
      'k-state-invalid': !isValid
    }, className);
    var wrapperClassNames = classNames('k-picker-wrap', {
      'k-state-default': !disabled,
      'k-state-disabled': disabled
    });
    return React.createElement("div", {
      ref: function ref(div) {
        _this._element = div;
      },
      className: rootClassName,
      onKeyDown: this.handleKeyDown,
      style: {
        width: width
      },
      onFocus: this.handleFocus,
      onBlur: this.handleBlur
    }, React.createElement("div", {
      ref: function ref(span) {
        _this._wrapper = span;
      },
      className: wrapperClassNames
    }, React.createElement(DateInput, {
      ref: function ref(dateInput) {
        _this._dateInput = dateInput;
      },
      id: id,
      format: format,
      formatPlaceholder: formatPlaceholder,
      disabled: disabled,
      tabIndex: !this.show ? tabIndex : -1,
      title: title,
      valid: this.validity.valid,
      validityStyles: validityStyles,
      validationMessage: validationMessage,
      required: required,
      min: min,
      max: max,
      value: this.value,
      onChange: this.handleValueChange,
      name: name
    }), React.createElement("span", __assign({
      role: "button",
      onMouseDown: this.handleIconMouseDown,
      onClick: this.handleDateIconClick,
      title: provideLocalizationService(this).toLanguageString(toggleDateTimeSelector, messages[toggleDateTimeSelector]),
      className: "k-select"
    }, {
      'aria-controls': this._popupId,
      'aria-label': provideLocalizationService(this).toLanguageString(toggleDateTimeSelector, messages[toggleDateTimeSelector])
    }), React.createElement("span", {
      className: "k-link k-link-date"
    }, React.createElement("span", {
      className: "k-icon k-i-calendar"
    })))), React.createElement(Popup, {
      show: this.show,
      animate: this._wrapper !== null,
      anchor: this._wrapper || undefined,
      className: "k-datetime-container k-group k-reset",
      id: this._popupId,
      anchorAlign: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      popupAlign: {
        horizontal: 'left',
        vertical: 'top'
      }
    }, React.createElement(DateTimeSelector, {
      ref: function ref(dateTimeSelector) {
        _this._dateTimeSelector = dateTimeSelector;
      },
      cancelButton: cancelButton,
      value: this.value,
      onChange: this.handleValueChange,
      disabled: disabled,
      weekNumber: weekNumber,
      min: this.min,
      max: this.max,
      focusedDate: focusedDate,
      format: format
    })));
  };

  DateTimePicker.prototype.setShow = function (show) {
    if (this.show === show) {
      return;
    }

    this.setState({
      show: show
    });
  };

  DateTimePicker.prototype.nextTick = function (f) {
    // XXX: use setTimeout due to async focus/blur events in IE, and missing relatedTarget prop.
    // XXX: https://github.com/facebook/react/issues/3751
    // Handles multiple focus events happening at the same time.
    clearTimeout(this.nextTickId);
    this.nextTickId = setTimeout(function () {
      return f();
    });
  };
  /**
   * @hidden
   */


  DateTimePicker.propTypes = {
    className: PropTypes.string,
    defaultShow: PropTypes.bool,
    defaultValue: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    focusedDate: PropTypes.instanceOf(Date),
    format: PropTypes.string,
    formatPlaceholder: PropTypes.oneOfType([PropTypes.oneOf(['wide', 'narrow', 'short', 'formatPattern']), PropTypes.shape({
      year: PropTypes.string,
      month: PropTypes.string,
      day: PropTypes.string,
      hour: PropTypes.string,
      minute: PropTypes.string,
      second: PropTypes.string
    })]),
    id: PropTypes.string,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    popupSettings: PropTypes.shape({
      animate: PropTypes.bool,
      appendTo: PropTypes.any,
      popupClass: PropTypes.string
    }),
    show: PropTypes.bool,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    weekNumber: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validationMessage: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.bool,
    valid: PropTypes.bool,
    cancelButton: PropTypes.bool
  };
  /**
   * @hidden
   */

  DateTimePicker.defaultProps = {
    defaultShow: false,
    defaultValue: null,
    disabled: false,
    format: 'g',
    max: MAX_DATE,
    min: MIN_DATE,
    popupSettings: {},
    tabIndex: 0,
    title: '',
    weekNumber: false,
    required: false,
    validityStyles: true,
    cancelButton: true
  };
  return DateTimePicker;
}(React.Component);

export { DateTimePicker };
registerForLocalization(DateTimePicker);