var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from './../main';
import { classNames, guid, Keys } from '@progress/kendo-react-common';
import { dispatchEvent } from '@progress/kendo-react-common';
import navigation from './utils/navigation';
import DropDownButtonItem from './DropDownButtonItem';
import ButtonItem from './ButtonItem';
import { Popup } from '@progress/kendo-react-popup';
import { getAnchorAlign, getPopupAlign } from './utils/popup';
/**
 * Represents the [KendoReact DropDownButton component]({% slug overview_dropdownbutton %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *        return (
 *            <DropDownButton text="Act">
 *                <DropDownButtonItem text="Item1" />
 *                <DropDownButtonItem text="Item2" />
 *                <DropDownButtonItem text="Item3" />
 *            </DropDownButton>
 *        );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */

var DropDownButton =
/** @class */
function (_super) {
  __extends(DropDownButton, _super);

  function DropDownButton() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * @hidden
     */


    _this.state = {
      opened: false,
      focused: false,
      focusedIndex: -1
    };
    _this.wrapper = null;
    _this.mainButton = null;
    _this.guid = guid();
    _this.buttonsData = [];

    _this.onKeyDown = function (event) {
      var _a = _this.state,
          opened = _a.opened,
          focusedIndex = _a.focusedIndex;

      if (event.altKey) {
        if (!opened && event.keyCode === Keys.down) {
          _this.dispatchPopupEvent(event, true);

          _this.setState({
            focusedIndex: 0,
            opened: true
          });
        } else if (opened && event.keyCode === Keys.up) {
          _this.dispatchPopupEvent(event, false);

          _this.setState({
            focusedIndex: -1,
            opened: false
          });
        }

        return;
      }

      var newState = undefined;

      if (event.keyCode === Keys.enter || event.keyCode === Keys.space) {
        if (focusedIndex >= 0) {
          _this.dispatchClickEvent(event, focusedIndex);
        } // Prevent default because otherwise when an item is selected
        // click on the default button gets emitted which opens the popup again.


        event.preventDefault();
        newState = {
          focusedIndex: opened ? -1 : 0,
          opened: !opened
        };

        _this.dispatchPopupEvent(event, newState.opened);
      } else if (opened && event.keyCode === Keys.esc) {
        newState = {
          focusedIndex: -1,
          opened: false
        };

        _this.dispatchPopupEvent(event, newState.opened);
      }

      if (opened) {
        var newFocused = navigation(focusedIndex, event.keyCode, event.altKey, _this.buttonsData.length);

        if (newFocused !== focusedIndex) {
          newState = newState || {};
          newState.focusedIndex = newFocused;
        }

        var arrowKey = event.keyCode === Keys.up || event.keyCode === Keys.down || event.keyCode === Keys.left || event.keyCode === Keys.right;

        if (!event.altKey && arrowKey) {
          // Needed to notify the parent listeners that event is handled.
          event.preventDefault();
        }
      }

      if (newState) {
        _this.setState(newState);
      }
    };

    _this.onFocus = function (event) {
      _this.setState({
        focused: true,
        focusedIndex: _this.state.opened ? 0 : -1
      });

      dispatchEvent(_this.props.onFocus, event, _this, undefined);
    };

    _this.onBlur = function (event) {
      _this.setState({
        focused: false,
        opened: false,
        focusedIndex: -1
      });

      dispatchEvent(_this.props.onBlur, event, _this, undefined);
      var fireCloseEvent = _this.state.opened;

      if (fireCloseEvent) {
        _this.dispatchPopupEvent(event, false);
      }
    };

    _this.onItemClick = function (event, clickedItemIndex) {
      _this.setState({
        focusedIndex: -1,
        opened: false
      });

      _this.dispatchClickEvent(event, clickedItemIndex);

      _this.dispatchPopupEvent(event, false);
    };

    _this.onItemDown = function (event) {
      if (document.activeElement === _this.element) {
        event.preventDefault();
      }
    };

    _this.mouseDown = function (event) {
      event.preventDefault();
    };

    _this.onClickMainButton = function (event) {
      if (!_this.buttonsData.length) {
        return;
      }

      var opened = _this.state.opened;
      var toOpen = !opened;

      _this.setState({
        opened: toOpen,
        focused: true,
        focusedIndex: toOpen ? 0 : -1
      });

      _this.dispatchPopupEvent(event, toOpen);
    };

    _this.dispatchPopupEvent = function (dispatchedEvent, open) {
      dispatchEvent(open ? _this.props.onOpen : _this.props.onClose, dispatchedEvent, _this, undefined);
    };

    return _this;
  }
  /**
   * @hidden
   */


  DropDownButton.prototype.render = function () {
    var _this = this;

    var rtl = this.isRtl();
    var dir = rtl ? 'rtl' : undefined;
    var _a = this.props,
        tabIndex = _a.tabIndex,
        disabled = _a.disabled;
    var focusedIndex = this.state.focusedIndex;
    this.buttonsData = this.props.items || React.Children.toArray(this.props.children).filter(function (child) {
      return child && child.type === DropDownButtonItem;
    }).map(function (child) {
      return child.props;
    });
    return React.createElement("div", {
      className: classNames('k-widget', 'k-dropdown-button', this.props.className, {
        'k-state-focused': this.state.focused
      }),
      onKeyDown: this.onKeyDown,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      dir: dir,
      ref: function ref(el) {
        return _this.wrapper = el;
      }
    }, React.createElement(Button, {
      onClick: this.onClickMainButton,
      onMouseDown: this.mouseDown,
      disabled: disabled || undefined,
      tabIndex: tabIndex,
      accessKey: this.props.accessKey,
      icon: this.props.icon,
      iconClass: this.props.iconClass,
      className: this.props.buttonClass,
      imageUrl: this.props.imageUrl,
      look: this.props.look,
      primary: this.props.primary,
      dir: dir,
      "aria-disabled": disabled,
      "aria-haspopup": true,
      "aria-expanded": this.state.opened,
      "aria-label": this.props.text + " dropdownbutton",
      ref: function ref(btn) {
        return _this.mainButton = btn && btn.element;
      },
      "aria-owns": this.guid,
      "aria-activedescendant": focusedIndex >= 0 ? this.guid + "-" + focusedIndex : undefined
    }, this.props.text), this.renderPopup(rtl));
  };
  /**
   * @hidden
   */


  DropDownButton.prototype.componentDidMount = function () {
    if (this.props.dir === undefined && this.isRtl()) {
      this.forceUpdate();
    }
  };
  /**
   * @hidden
   */


  DropDownButton.prototype.componentDidUpdate = function () {
    if (this.state.focused && this.element) {
      // firefox in mac does not focus on mouse-down, next line fixes this
      this.element.focus();
    }
  };

  Object.defineProperty(DropDownButton.prototype, "element", {
    /**
     * The DOM element of main button.
     */
    get: function get() {
      return this.mainButton;
    },
    enumerable: true,
    configurable: true
  });

  DropDownButton.prototype.dispatchClickEvent = function (dispatchedEvent, index) {
    if (!this.isItemDisabled(index)) {
      dispatchEvent(this.props.onItemClick, dispatchedEvent, this, {
        item: this.buttonsData[index],
        itemIndex: index
      });
    }
  };

  DropDownButton.prototype.renderPopup = function (rtl) {
    var _a = this.props.popupSettings,
        popupSettings = _a === void 0 ? {} : _a;
    return React.createElement(Popup, {
      anchor: this.wrapper || undefined,
      show: this.state.opened,
      animate: popupSettings.animate,
      popupClass: classNames('k-list-container k-reset k-group', popupSettings.popupClass),
      anchorAlign: popupSettings.anchorAlign || getAnchorAlign(rtl),
      popupAlign: popupSettings.popupAlign || getPopupAlign(rtl),
      style: rtl ? {
        direction: 'rtl'
      } : undefined
    }, React.createElement("ul", {
      className: "k-list k-reset",
      role: "menu",
      id: this.guid
    }, this.renderChildItems()));
  };

  DropDownButton.prototype.renderChildItems = function () {
    var _this = this;

    var _a = this.props,
        itemRender = _a.itemRender,
        textField = _a.textField;
    return this.buttonsData.length > 0 ? this.buttonsData.map(function (item, index) {
      return React.createElement(ButtonItem, {
        dataItem: item,
        textField: textField,
        focused: _this.state.focusedIndex === index,
        onClick: _this.onItemClick,
        onDown: _this.onItemDown,
        itemRender: itemRender,
        index: index,
        key: index,
        id: _this.guid + "-" + index
      });
    }) : null;
  };

  DropDownButton.prototype.isItemDisabled = function (index) {
    return this.buttonsData[index] ? this.buttonsData[index].disabled : this.props.disabled;
  };

  DropDownButton.prototype.isRtl = function () {
    return this.props.dir !== undefined ? this.props.dir === 'rtl' : !!this.wrapper && getComputedStyle(this.wrapper).direction === 'rtl';
  };
  /**
   * @hidden
   */


  DropDownButton.propTypes = {
    accessKey: PropTypes.string,
    primary: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onItemClick: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    text: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    textField: PropTypes.string,
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    imageUrl: PropTypes.string,
    popupSettings: PropTypes.object,
    itemRender: PropTypes.any,
    look: PropTypes.string,
    className: PropTypes.string,
    buttonClass: PropTypes.string,
    dir: PropTypes.string
  };
  return DropDownButton;
}(React.Component);

export default DropDownButton;