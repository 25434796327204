var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import ToolbarItem from './tools/ToolbarItem';
import ToolbarButton from './tools/ToolbarButton';
import ButtonGroup from '../ButtonGroup';
import { classNames, Keys } from '@progress/kendo-react-common';
/**
 * Represents the [KendoReact Toolbar component]({% slug overview_toolbar %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *       return (
 *          <Toolbar>
 *              <ToolbarItem>
 *                  <ButtonGroup>
 *                      <Button togglable={true} icon="bold" />
 *                      <Button togglable={true} icon="italic" />
 *                      <Button togglable={true} icon="underline" />
 *                  </ButtonGroup>
 *              </ToolbarItem>
 *              <ToolbarItem>
 *                  <ButtonGroup>
 *                      <Button icon="hyperlink">Insert Link</Button>
 *                      <Button icon="image">Insert Image</Button>
 *                      <Button icon="table">Insert Table</Button>
 *                  </ButtonGroup>
 *              </ToolbarItem>
 *          </Toolbar>
 *       );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */

var Toolbar =
/** @class */
function (_super) {
  __extends(Toolbar, _super);

  function Toolbar() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this._element = null;
    _this.buttons = [];
    _this.focusedIndex = 0;
    _this.counter = 0;
    _this.offsetHeight = 0;
    _this.offsetWidth = 0;

    _this.mapToolbarChild = function (toolbarItem) {
      if (toolbarItem && toolbarItem.type === ToolbarItem) {
        return React.cloneElement(toolbarItem, toolbarItem.props, React.Children.map(toolbarItem.props.children, _this.mapItemChildren));
      }

      return toolbarItem;
    };

    _this.mapItemChildren = function (tool) {
      if (tool.type === ButtonGroup) {
        return React.cloneElement(tool, tool.props, React.Children.map(tool.props.children, _this.mapButton));
      }

      return _this.mapButton(tool);
    };

    _this.mapButton = function (button) {
      var index = _this.counter;
      _this.counter++;
      return React.createElement(ToolbarButton, {
        ref: function ref(b) {
          return _this.buttonRef(b, index);
        },
        tabIndex: _this.focusedIndex === index ? _this.props.tabIndex || Toolbar.defaultProps.tabIndex : -1,
        button: button
      });
    };

    _this.buttonRef = function (button, index) {
      _this.buttons[index] = button;

      if (!button && !_this.buttons.find(function (b) {
        return b !== null;
      })) {
        _this.buttons.length = 0;
      }
    };

    _this.onKeyDown = function (event) {
      var target = event.target;
      var _a = _this,
          focusedIndex = _a.focusedIndex,
          buttons = _a.buttons;
      var arrowKey = event.keyCode === Keys.left || event.keyCode === Keys.right;

      if (!arrowKey || event.defaultPrevented || buttons.findIndex(function (b) {
        return b !== null && b.element === target;
      }) === -1) {
        return;
      }

      if (event.keyCode === Keys.left) {
        _this.focusButton(focusedIndex, focusedIndex - 1);
      } else {
        _this.focusButton(focusedIndex, focusedIndex + 1);
      }
    };

    _this.onWindowResize = function (event) {
      var element = _this.element;

      if (!element) {
        return;
      }

      var offsetWidth = element.offsetWidth;
      var offsetHeight = element.offsetHeight;

      if (_this.offsetWidth !== offsetWidth || _this.offsetHeight !== offsetHeight) {
        _this.offsetWidth = offsetWidth;
        _this.offsetHeight = offsetHeight;
        var newSizes = {
          offsetWidth: _this.offsetWidth,
          offsetHeight: _this.offsetHeight
        };

        if (_this.props.onResize) {
          _this.props.onResize.call(undefined, __assign({
            target: _this
          }, newSizes, {
            nativeEvent: event
          }));
        }
      }
    };

    return _this;
  }

  Object.defineProperty(Toolbar.prototype, "element", {
    /**
     * Returns the HTML element of the Toolbar component.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  Toolbar.prototype.componentDidMount = function () {
    window.addEventListener('resize', this.onWindowResize);
    var element = this.element;

    if (element) {
      this.offsetWidth = element.offsetWidth;
      this.offsetHeight = element.offsetHeight;
    }
  };
  /**
   * @hidden
   */


  Toolbar.prototype.componentDidUpdate = function () {
    var _this = this;

    var element = this.element;

    if (!element) {
      return;
    }

    var focused = element.querySelector('button:focus');
    var prevFocusedIndex = this.focusedIndex;

    if (!focused) {
      this.focusedIndex = 0;
    } else {
      var focusedIndex = this.buttons.findIndex(function (b) {
        return b !== null && b.element === focused;
      });

      if (focusedIndex !== -1 && focusedIndex !== this.focusedIndex) {
        this.focusedIndex = focusedIndex;
      }
    }

    if (prevFocusedIndex !== this.focusedIndex) {
      var _a = this.props.tabIndex,
          tabIndex_1 = _a === void 0 ? Toolbar.defaultProps.tabIndex : _a;
      this.buttons.forEach(function (button, index) {
        if (button) {
          button.tabIndex = index === _this.focusedIndex && button.tabIndex === -1 ? tabIndex_1 : -1;
        }
      });
    }
  };
  /**
   * @hidden
   */


  Toolbar.prototype.componentWillUnmount = function () {
    window.removeEventListener('resize', this.onWindowResize);
  };
  /**
   * @hidden
   */


  Toolbar.prototype.render = function () {
    var _this = this;

    this.counter = 0;
    return React.createElement("div", {
      className: classNames('k-widget k-toolbar', this.props.className),
      style: this.props.style,
      role: "toolbar",
      dir: this.props.dir,
      ref: function ref(element) {
        return _this._element = element;
      },
      onKeyDown: this.onKeyDown
    }, React.Children.map(this.props.children, this.mapToolbarChild));
  };

  Toolbar.prototype.focusButton = function (prevIndex, index) {
    var toolbarButton = this.buttons[index];

    if (toolbarButton) {
      var newFocused = toolbarButton.element;
      var currentFocused = this.element && this.element.querySelector('button:focus');
      var _a = this.props.tabIndex,
          tabIndex = _a === void 0 ? Toolbar.defaultProps.tabIndex : _a;
      this.focusedIndex = index;

      if (currentFocused !== newFocused) {
        toolbarButton.tabIndex = tabIndex;
        toolbarButton.focus();
        var prevButton = this.buttons[prevIndex];

        if (prevButton) {
          prevButton.tabIndex = -1;
        }
      }
    }
  };
  /**
   * @hidden
   */


  Toolbar.propTypes = {
    tabIndex: PropTypes.number,
    dir: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onResize: PropTypes.func
  };
  /**
   * @hidden
   */

  Toolbar.defaultProps = {
    tabIndex: 0
  };
  return Toolbar;
}(React.Component);

export default Toolbar;