var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { cloneDate, isEqual } from '@progress/kendo-date-math';
import { CalendarNavigationItem } from './CalendarNavigationItem';
import { dateInRange } from '../../utils';
import { Virtualization } from '../../virtualization/Virtualization';
var ITEMS_COUNT = 30;
/**
 * @hidden
 */

var Navigation =
/** @class */
function (_super) {
  __extends(Navigation, _super);

  function Navigation(props) {
    var _this = _super.call(this, props) || this;

    _this.topOffset = 0;

    _this.handleVirtualizationMount = function (virtualization) {
      _this.virtualization = virtualization;

      if (_this.virtualization && _this.list) {
        _this.list.style.transform = "translateY(" + _this.topOffset + "px)";
        var viewDate = dateInRange(_this.props.focusedDate, _this.props.min, _this.props.max);

        var indexToScroll = _this.props.service.skip(viewDate, _this.props.min);

        _this.virtualization.scrollToIndex(indexToScroll);
      }
    };

    _this.buildNavigationItem = function (date) {
      var text = _this.props.service.navigationTitle(date);

      var isRangeStart = _this.props.service.isRangeStart(date);

      var uniqueID = "kendo-react-calendar-nav-item-" + date.getTime();
      var navigationItemProps = {
        text: text,
        value: date,
        isRangeStart: isRangeStart,
        view: _this.props.activeView,
        onClick: _this.handleDateChange
      };
      return _this.props.navigationItem ? React.createElement(_this.props.navigationItem, __assign({}, navigationItemProps, {
        key: uniqueID
      }), text) : React.createElement(CalendarNavigationItem, __assign({}, navigationItemProps, {
        key: uniqueID
      }), text);
    };

    _this.calculateHeights = function () {
      var calendarHeight = _this.props.dom.calendarHeight;
      _this.itemHeight = _this.props.dom.navigationItemHeight || 1;
      _this.maxViewHeight = _this.props.dom.monthViewHeight;
      _this.topOffset = (calendarHeight - _this.itemHeight) / 2;
      _this.bottomOffset = calendarHeight - _this.itemHeight;
    };

    _this.handleDateChange = function (value, event) {
      var onChange = _this.props.onChange;

      if (onChange) {
        var args = {
          value: cloneDate(value),
          target: _this,
          nativeEvent: event && event.nativeEvent,
          syntheticEvent: event
        };
        onChange.call(undefined, args);
      }
    };

    _this.handleScrollAction = function (_a) {
      var scrollAction = _a.scrollAction,
          pageAction = _a.pageAction;
      var skip = pageAction ? pageAction.skip : _this.state.skip;

      if (_this.state.skip !== skip) {
        _this.setState({
          skip: skip
        });
      }

      if (_this.list && scrollAction) {
        var translate = "translateY(" + scrollAction.offset + "px)";
        _this.list.style.transform = translate;
      }
    };

    _this.lastView = _this.props.activeView;
    _this.state = {
      skip: _this.props.service.skip(_this.props.focusedDate, _this.props.min)
    };
    return _this;
  }

  Object.defineProperty(Navigation.prototype, "take", {
    get: function get() {
      return this.props.take !== undefined ? this.props.take : Navigation.defaultProps.take;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  Navigation.prototype.componentWillReceiveProps = function (nextProps) {
    if (this.props.activeView === nextProps.activeView && isEqual(this.props.focusedDate, nextProps.focusedDate)) {
      return null;
    }

    var viewDate = dateInRange(nextProps.focusedDate, nextProps.min, nextProps.max);
    this.indexToScroll = nextProps.service.skip(viewDate, nextProps.min);
  };
  /**
   * @hidden
   */


  Navigation.prototype.componentDidUpdate = function (_prevProps, _prevState) {
    if (this.indexToScroll !== undefined && this.virtualization) {
      this.virtualization.scrollToIndex(this.indexToScroll);
      this.forceUpdate();
    }

    this.lastView = this.props.activeView;
    this.indexToScroll = undefined;
  };

  Navigation.prototype.render = function () {
    var _this = this;

    this.calculateHeights();
    var didViewChange = this.lastView !== this.props.activeView;
    var focusedDate = dateInRange(this.props.focusedDate, this.props.min, this.props.max);
    var skip = !didViewChange ? this.state.skip : this.props.service.skip(focusedDate, this.props.min);
    var total = this.props.service.total(this.props.min, this.props.max);
    var take = this.getTake(skip, total);
    var viewDate = this.props.service.addToDate(this.props.min, skip);
    var dates = this.props.service.datesList(viewDate, take);
    var navigationBody = React.createElement(Virtualization, {
      skip: skip,
      take: this.take,
      total: total,
      itemHeight: this.itemHeight,
      topOffset: this.topOffset,
      bottomOffset: this.bottomOffset,
      maxScrollDifference: this.maxViewHeight,
      onScrollAction: this.handleScrollAction,
      onMount: this.handleVirtualizationMount
    }, React.createElement("ul", {
      ref: function ref(el) {
        _this.list = el;
      },
      className: "k-reset"
    }, dates.map(function (date) {
      return _this.buildNavigationItem(date);
    })));
    return React.createElement("div", {
      className: "k-calendar-navigation"
    }, React.createElement("span", {
      className: "k-calendar-navigation-highlight"
    }), this.props.dom.didCalculate ? navigationBody : null);
  };

  Navigation.prototype.getTake = function (skip, total) {
    return Math.min(total - skip, this.take);
  };

  Navigation.propTypes = {
    activeView: PropTypes.number.isRequired,
    focusedDate: PropTypes.instanceOf(Date).isRequired,
    max: PropTypes.instanceOf(Date).isRequired,
    min: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func,
    take: PropTypes.number
  };
  Navigation.defaultProps = {
    take: ITEMS_COUNT
  };
  return Navigation;
}(React.Component);

export { Navigation };