var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import React from 'react';
import { withImmutableProps } from '../utils';
import defaultSelectionRenderer from './defaultSelectionRenderer';
import Slider from './Slider';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
export default withImmutableProps(function (_ref) {
  var renderSelection = _ref.renderSelection,
      setDisplayDate = _ref.setDisplayDate;
  return {
    renderSelection: function renderSelection(values, _ref2) {
      var scrollToDate = _ref2.scrollToDate,
          displayDate = _ref2.displayDate,
          props = _objectWithoutProperties(_ref2, ['scrollToDate', 'displayDate']);

      if (!values.length) {
        return null;
      }

      var dates = values.sort();
      var index = values.indexOf(format(parse(displayDate), 'YYYY-MM-DD'));
      return React.createElement(Slider, {
        index: index !== -1 ? index : dates.length - 1,
        onChange: function onChange(index) {
          return setDisplayDate(dates[index], function () {
            return setTimeout(function () {
              return scrollToDate(dates[index], 0, true);
            }, 50);
          });
        }
      }, dates.map(function (value) {
        return defaultSelectionRenderer(value, _extends({}, props, {
          key: index,
          scrollToDate: scrollToDate,
          shouldAnimate: false
        }));
      }));
    }
  };
});