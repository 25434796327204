var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { getDate, cloneDate } from '@progress/kendo-date-math';
import { classNames, guid, Keys } from '@progress/kendo-react-common';
import { DateInput } from '../dateinput/DateInput';
import { Calendar } from '../calendar/components/Calendar';
import { MIN_DATE, MAX_DATE, setTime } from '../utils';
import { isInRangePicker } from '../utils';
import { messages, toggleCalendar } from '../messages';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';

var DatePicker =
/** @class */
function (_super) {
  __extends(DatePicker, _super);

  function DatePicker(props) {
    var _this = _super.call(this, props) || this;

    _this._popupId = guid();
    _this._element = null;
    _this._wrapper = null;
    _this._dateInput = null;
    _this._calendar = null;
    _this.shouldFocusDateInput = false; // Using this, to avoid bumping the react version
    // to 16.3 and use `createRef()`;
    // Inline arrow functions is a performance hit
    // so... we use this.

    _this.setDateInputRef = function (dateInput) {
      _this._dateInput = dateInput;
    };

    _this.setCalendarRef = function (calendar) {
      _this._calendar = calendar;
    };

    _this.nextValue = function (nextProps, nextState) {
      return nextProps.value !== undefined ? nextProps.value : nextState.value;
    };

    _this.nextShow = function (nextProps, nextState) {
      return nextProps.show !== undefined ? nextProps.show : nextState.show;
    };

    _this.handleInputValueChange = function (event) {
      _this.handleValueChange(event.value, event);
    };

    _this.handleCalendarValueChange = function (event) {
      var value = _this.mergeTime(event.value);

      _this.handleValueChange(value, event);
    };

    _this.handleValueChange = function (value, event) {
      _this.setState({
        value: cloneDate(value || undefined),
        show: false
      });

      _this.valueDuringOnChange = value;
      _this.showDuringOnChange = false;
      _this.shouldFocusDateInput = true;
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange.call(undefined, {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: _this.value,
          show: _this.show,
          target: _this
        });
      }

      _this.valueDuringOnChange = undefined;
      _this.showDuringOnChange = undefined;
    };

    _this.handleFocus = function (event) {
      clearTimeout(_this.nextTickId);

      if (_this._wrapper) {
        _this._wrapper.classList.add('k-state-focused');
      }

      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      _this.nextTick(function () {
        if (_this._wrapper) {
          _this._wrapper.classList.remove('k-state-focused');
        }

        _this.setShow(false);
      });

      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handleIconClick = function () {
      if (_this.props.disabled) {
        return;
      }

      _this.shouldFocusDateInput = true;

      _this.setShow(!_this.show);
    };

    _this.handleIconMouseDown = function (event) {
      event.preventDefault();
    };

    _this.handleKeyDown = function (event) {
      var altKey = event.altKey,
          keyCode = event.keyCode;

      if (keyCode === Keys.esc) {
        _this.setShow(false);

        return;
      }

      if (altKey && (keyCode === Keys.up || keyCode === Keys.down)) {
        event.preventDefault();
        event.stopPropagation();
        _this.shouldFocusDateInput = keyCode === Keys.up;

        _this.setShow(keyCode === Keys.down);
      }
    };

    _this.state = {
      value: _this.props.defaultValue || DatePicker.defaultProps.defaultValue,
      show: _this.props.defaultShow || DatePicker.defaultProps.defaultShow
    };
    return _this;
  }

  Object.defineProperty(DatePicker.prototype, "element", {
    /**
     * Gets the wrapping element of the DatePicker.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "dateInput", {
    /**
     * Gets the DateInput component inside the DatePicker component.
     */
    get: function get() {
      return this._dateInput;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "calendar", {
    /**
     * Gets the Calendar component inside the DatePicker component.
     */
    get: function get() {
      return this._calendar;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "value", {
    /**
     * Gets the value of the DatePicker.
     */
    get: function get() {
      var value = this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
      return value !== null ? cloneDate(value) : null;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "show", {
    /**
     * Gets the popup state of the DatePicker.
     */
    get: function get() {
      return this.showDuringOnChange !== undefined ? this.showDuringOnChange : this.props.show !== undefined ? this.props.show : this.state.show;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "name", {
    /**
     * Gets the `name` property of the DatePicker.
     */
    get: function get() {
      return this.props.name;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "min", {
    get: function get() {
      return this.props.min !== undefined ? this.props.min : DatePicker.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "max", {
    get: function get() {
      return this.props.max !== undefined ? this.props.max : DatePicker.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "validity", {
    /**
     * Represents the validity state into which the DatePicker is set.
     */
    get: function get() {
      var inRange = isInRangePicker(this.value, this.min, this.max);
      var customError = this.props.validationMessage !== undefined;
      var isValid = (!this.required || this.value !== null) && inRange;
      var valid = this.props.valid !== undefined ? this.props.valid : isValid;
      return {
        customError: customError,
        rangeOverflow: this.value && this.max.getTime() < this.value.getTime() || false,
        rangeUnderflow: this.value && this.value.getTime() < this.min.getTime() || false,
        valid: valid,
        valueMissing: this.value === null
      };
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "validityStyles", {
    /**
     * @hidden
     */
    get: function get() {
      return this.props.validityStyles !== undefined ? this.props.validityStyles : DatePicker.defaultProps.validityStyles;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DatePicker.prototype, "required", {
    /**
     * @hidden
     */
    get: function get() {
      return this.props.required !== undefined ? this.props.required : DatePicker.defaultProps.required;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  DatePicker.prototype.componentDidMount = function () {
    if (this.show) {
      // If defaultShow is true during the initial render, the popup is not aligned.
      this.forceUpdate();
    }

    if (this._dateInput && this._dateInput.element) {
      this._dateInput.element.setAttribute('aria-haspopup', 'true');

      this._dateInput.element.setAttribute('aria-expanded', "" + this.show);
    }
  };
  /**
   * @hidden
   */


  DatePicker.prototype.componentDidUpdate = function (prevProps) {
    if (this._dateInput && this._dateInput.element) {
      this._dateInput.element.setAttribute('aria-expanded', "" + this.show);
    }

    if (this._calendar && this._calendar.element && this.show && !prevProps.show) {
      this._calendar.element.focus({
        preventScroll: true
      });
    }

    if (this._dateInput && this._dateInput.element && !this.show && this.shouldFocusDateInput) {
      this._dateInput.element.focus({
        preventScroll: true
      });
    }

    this.shouldFocusDateInput = false;
  };
  /**
   * @hidden
   */


  DatePicker.prototype.componentWillUnmount = function () {
    clearTimeout(this.nextTickId);
  };
  /**
   * @hidden
   */


  DatePicker.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        disabled = _a.disabled,
        tabIndex = _a.tabIndex,
        title = _a.title,
        id = _a.id,
        format = _a.format,
        formatPlaceholder = _a.formatPlaceholder,
        min = _a.min,
        max = _a.max,
        weekNumber = _a.weekNumber,
        focusedDate = _a.focusedDate,
        className = _a.className,
        width = _a.width,
        name = _a.name,
        validationMessage = _a.validationMessage,
        required = _a.required,
        validityStyles = _a.validityStyles;

    var _b = this.props.popupSettings,
        popupClass = _b.popupClass,
        otherPopupSettings = __rest(_b, ["popupClass"]);

    var show = this.show;
    var value = this.value;
    var sanitizedValue = value && getDate(value);
    var isValid = !this.validityStyles || this.validity.valid;
    var rootClassName = classNames('k-widget k-datepicker', {
      'k-state-invalid': !isValid
    }, className);
    var wrapperClassNames = classNames('k-picker-wrap', {
      'k-state-default': !disabled,
      'k-state-disabled': disabled
    });
    var popupClassNames = classNames('k-calendar-container k-group k-reset', popupClass);
    var dateInputProps = {
      disabled: disabled,
      format: format,
      formatPlaceholder: formatPlaceholder,
      id: id,
      max: max,
      min: min,
      name: name,
      onChange: this.handleInputValueChange,
      required: required,
      tabIndex: !show ? tabIndex : -1,
      title: title,
      valid: this.validity.valid,
      validationMessage: validationMessage,
      validityStyles: validityStyles,
      value: value
    };

    var popupProps = __assign({
      show: show,
      animate: this._wrapper !== null,
      anchor: this._wrapper || undefined,
      className: popupClassNames,
      id: this._popupId,
      anchorAlign: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      popupAlign: {
        horizontal: 'left',
        vertical: 'top'
      }
    }, otherPopupSettings);

    var calendarProps = {
      disabled: disabled,
      value: sanitizedValue,
      min: min,
      max: max,
      weekNumber: weekNumber,
      focusedDate: focusedDate,
      onChange: this.handleCalendarValueChange
    };
    var calendar = this.props.calendar ? React.createElement(this.props.calendar, __assign({}, calendarProps)) : React.createElement(Calendar, __assign({
      ref: this.setCalendarRef
    }, calendarProps));
    return React.createElement("span", {
      ref: function ref(span) {
        _this._element = span;
      },
      className: rootClassName,
      onKeyDown: this.handleKeyDown,
      style: {
        width: width
      },
      onFocus: this.handleFocus,
      onBlur: this.handleBlur
    }, React.createElement("span", {
      ref: function ref(span) {
        _this._wrapper = span;
      },
      className: wrapperClassNames
    }, this.props.dateInput ? React.createElement(this.props.dateInput, __assign({}, dateInputProps)) : React.createElement(DateInput, __assign({
      ref: this.setDateInputRef
    }, dateInputProps)), React.createElement("span", __assign({
      role: "button",
      onMouseDown: this.handleIconMouseDown,
      onClick: this.handleIconClick,
      title: provideLocalizationService(this).toLanguageString(toggleCalendar, messages[toggleCalendar]),
      className: "k-select"
    }, {
      'aria-controls': this._popupId,
      'aria-label': provideLocalizationService(this).toLanguageString(toggleCalendar, messages[toggleCalendar])
    }), React.createElement("span", {
      className: "k-icon k-i-calendar"
    }))), this.props.popup ? React.createElement(this.props.popup, __assign({}, popupProps), calendar, " ") : React.createElement(Popup, __assign({}, popupProps), calendar));
  };

  DatePicker.prototype.setShow = function (show) {
    if (this.show === show) {
      return;
    }

    this.setState({
      show: show
    });
  };

  DatePicker.prototype.mergeTime = function (value) {
    return this.value && value ? setTime(value, this.value) : value;
  };

  DatePicker.prototype.nextTick = function (f) {
    // XXX: use setTimeout due to async focus/blur events in IE, and missing relatedTarget prop.
    // XXX: https://github.com/facebook/react/issues/3751
    // Handles multiple focus events happening at the same time.
    clearTimeout(this.nextTickId);
    this.nextTickId = setTimeout(function () {
      return f();
    });
  };
  /**
   * @hidden
   */


  DatePicker.propTypes = {
    className: PropTypes.string,
    defaultShow: PropTypes.bool,
    defaultValue: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    focusedDate: PropTypes.instanceOf(Date),
    format: PropTypes.string,
    formatPlaceholder: PropTypes.oneOfType([PropTypes.oneOf(['wide', 'narrow', 'short', 'formatPattern']), PropTypes.shape({
      year: PropTypes.string,
      month: PropTypes.string,
      day: PropTypes.string,
      hour: PropTypes.string,
      minute: PropTypes.string,
      second: PropTypes.string
    })]),
    id: PropTypes.string,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    popupSettings: PropTypes.shape({
      animate: PropTypes.bool,
      appendTo: PropTypes.any,
      popupClass: PropTypes.string
    }),
    show: PropTypes.bool,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    weekNumber: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validationMessage: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.bool,
    valid: PropTypes.bool
  };
  /**
   * @hidden
   */

  DatePicker.defaultProps = {
    defaultShow: false,
    defaultValue: null,
    disabled: false,
    format: 'd',
    max: MAX_DATE,
    min: MIN_DATE,
    popupSettings: {},
    tabIndex: 0,
    title: '',
    weekNumber: false,
    required: false,
    validityStyles: true
  };
  return DatePicker;
}(React.Component);

export { DatePicker };
registerForLocalization(DatePicker);