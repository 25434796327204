var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, date, time, dateTimePickerCancel as cancel, dateTimePickerSet as set } from '../messages';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Calendar } from '../calendar/components/Calendar';
import { TimePart } from '../timepicker/TimePart';
import { setTime, getToday, MIN_TIME, MAX_TIME } from '../utils';
import { isEqualDate } from '@progress/kendo-date-math';
import { Keys, classNames } from '@progress/kendo-react-common';
import { MIDNIGHT_DATE } from '../defaults';
import { getNow } from '../timepicker/utils';
/**
 * @hidden
 */

var DateTimeSelector =
/** @class */
function (_super) {
  __extends(DateTimeSelector, _super);

  function DateTimeSelector(props) {
    var _this = _super.call(this, props) || this;

    _this.focus = function (args) {
      // Async to avoid stealing the focus from the DateInput on close
      Promise.resolve().then(function () {
        if (_this.state.tab === 'time' && _this._timePart) {
          _this._timePart.focus(args);
        }

        if (_this.state.tab === 'date' && _this._calendar && _this._calendar.element) {
          _this._calendar.element.focus(args);
        }
      });
    };

    _this.move = function (direction) {
      if (direction === 'right' && _this.state.tab === 'time') {
        return;
      }

      if (direction === 'left' && _this.state.tab === 'date') {
        return;
      }

      var nextPart = direction === 'left' ? 'date' : 'time';
      _this.shouldFocusPart = true;

      _this.setState({
        tab: nextPart
      });
    };

    _this.handleReject = function () {
      _this.setState({
        dateValue: _this.props.value,
        timeValue: _this.props.value || MIDNIGHT_DATE
      });
    };

    _this.handleAccept = function (event) {
      if (!_this.state.dateValue || !_this.state.timeValue || !_this.hasDateValue) {
        return;
      }

      var value = _this.mergeDate(_this.state.dateValue, _this.state.timeValue);

      _this.props.onChange.call(undefined, {
        syntheticEvent: event,
        nativeEvent: event.nativeEvent,
        value: value,
        target: _this
      });
    };

    _this.handleNowClick = function () {
      _this.setState({
        timeValue: getNow()
      });
    };

    _this.handleCalendarValueChange = function (event) {
      event.syntheticEvent.stopPropagation();

      _this.setState({
        dateValue: event.value,
        tab: 'time'
      });

      _this.shouldFocusPart = true;
    };

    _this.handleTimeListContainerChange = function (candidate) {
      _this.setState({
        timeValue: candidate
      });
    };

    _this.handleDateClick = function (event) {
      event.stopPropagation();

      _this.move('left');
    };

    _this.handleTimeClick = function (event) {
      event.stopPropagation();

      _this.move('right');
    };

    _this.handleKeyDown = function (event) {
      var keyCode = event.keyCode,
          altKey = event.altKey;

      switch (keyCode) {
        case Keys.enter:
          if (!_this.hasActiveButton() && _this.hasDateValue) {
            _this.handleAccept(event);
          }

          return;

        case Keys.left:
          if (!altKey) {
            return;
          }

          _this.move('left');

          return;

        case Keys.right:
          if (!altKey) {
            return;
          }

          _this.move('right');

          return;

        default:
          return;
      }
    };

    _this.state = {
      tab: 'date',
      dateValue: _this.props.value,
      timeValue: _this.props.value || MIDNIGHT_DATE
    };
    return _this;
  }

  Object.defineProperty(DateTimeSelector.prototype, "calendar", {
    get: function get() {
      return this._calendar;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimeSelector.prototype, "timePart", {
    get: function get() {
      return this._timePart;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateTimeSelector.prototype, "hasDateValue", {
    get: function get() {
      return this.state.dateValue !== null;
    },
    enumerable: true,
    configurable: true
  });

  DateTimeSelector.prototype.componentDidUpdate = function () {
    if (this.shouldFocusPart) {
      this.focus({
        preventScroll: true
      });
    }

    this.shouldFocusPart = false;
  };

  DateTimeSelector.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        disabled = _a.disabled,
        cancelButton = _a.cancelButton,
        min = _a.min,
        max = _a.max,
        weekNumber = _a.weekNumber,
        focusedDate = _a.focusedDate,
        format = _a.format;
    var rootClassName = classNames({
      'k-date-tab': this.state.tab === 'date',
      'k-time-tab': this.state.tab === 'time',
      'k-state-disabled': disabled
    }, 'k-datetime-wrap');
    var setButtonClassName = classNames({
      'k-state-disabled': !this.hasDateValue
    }, 'k-time-accept k-button k-primary');
    var localizationService = provideLocalizationService(this);
    var dateMessage = localizationService.toLanguageString(date, messages[date]);
    var timeMessage = localizationService.toLanguageString(time, messages[time]);
    var cancelMessage = localizationService.toLanguageString(cancel, messages[cancel]);
    var setMessage = localizationService.toLanguageString(set, messages[set]);
    return React.createElement("div", {
      onKeyDown: this.handleKeyDown,
      className: rootClassName,
      tabIndex: -1
    }, React.createElement("div", {
      className: "k-datetime-buttongroup"
    }, React.createElement(ButtonGroup, {
      width: "100%"
    }, React.createElement(Button, {
      selected: this.state.tab === 'date',
      togglable: true,
      onClick: this.handleDateClick
    }, dateMessage), React.createElement(Button, {
      selected: this.state.tab === 'time',
      togglable: true,
      onClick: this.handleTimeClick
    }, timeMessage))), React.createElement("div", {
      className: "k-datetime-selector"
    }, React.createElement("div", {
      className: "k-datetime-calendar-wrap"
    }, React.createElement(Calendar, {
      key: 0,
      disabled: disabled || this.state.tab !== 'date',
      ref: function ref(calendar) {
        _this._calendar = calendar;
      },
      value: this.state.dateValue,
      min: min,
      max: max,
      weekNumber: weekNumber,
      focusedDate: focusedDate,
      onChange: this.handleCalendarValueChange,
      navigation: false
    })), React.createElement("div", {
      className: "k-datetime-time-wrap"
    }, React.createElement(TimePart, {
      key: 1,
      onNowClick: this.handleNowClick,
      disabled: disabled || this.state.tab !== 'time',
      ref: function ref(timePart) {
        _this._timePart = timePart;
      },
      min: this.normalizeRange(min, this.state.dateValue) || MIN_TIME,
      max: this.normalizeRange(max, this.state.dateValue) || MAX_TIME,
      value: this.state.timeValue,
      format: format,
      onChange: this.handleTimeListContainerChange
    }))), React.createElement("div", {
      className: "k-datetime-footer k-action-buttons"
    }, cancelButton && React.createElement("button", __assign({
      ref: function ref(btn) {
        _this._cancelButton = btn;
      },
      className: "k-button k-time-cancel",
      onClick: this.handleReject,
      title: cancelMessage
    }, {
      'aria-label': cancelMessage
    }), cancelMessage), React.createElement("button", __assign({
      ref: function ref(btn) {
        _this._acceptButton = btn;
      },
      className: setButtonClassName,
      onClick: this.handleAccept,
      title: setMessage
    }, {
      'aria-label': setMessage
    }), setMessage)));
  };

  DateTimeSelector.prototype.normalizeRange = function (candidate, value) {
    return isEqualDate(candidate, value || getToday()) ? candidate : null;
  };

  DateTimeSelector.prototype.hasActiveButton = function () {
    if (!this._acceptButton) {
      return false;
    }

    return document.activeElement === this._acceptButton || document.activeElement === this._cancelButton;
  };

  DateTimeSelector.prototype.mergeTime = function (current, candidate) {
    return current && candidate ? setTime(candidate, current) : candidate;
  };

  DateTimeSelector.prototype.mergeDate = function (candidate, value) {
    return candidate ? setTime(candidate || getToday(), value) : value;
  };

  return DateTimeSelector;
}(React.Component);

export { DateTimeSelector };
registerForLocalization(DateTimeSelector);