var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { cloneDate } from '@progress/kendo-date-math';
import { classNames, guid, Keys } from '@progress/kendo-react-common';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, toggleTimeSelector, toggleClock } from '../messages';
import { DateInput } from '../dateinput/DateInput';
import { TimeSelector } from './TimeSelector';
import { MIDNIGHT_DATE, MIN_TIME, MAX_TIME, setTime } from '../utils';
import { isInRange, isSmallerThanMin, isBiggerThanMax } from './utils';

var TimePicker =
/** @class */
function (_super) {
  __extends(TimePicker, _super);

  function TimePicker(props) {
    var _this = _super.call(this, props) || this;

    _this._popupId = guid();
    _this._element = null;
    _this._wrapper = null;
    _this._dateInput = null;
    _this._timeSelector = null;
    _this.shouldFocusDateInput = false; // Using `this` to avoid bumping the React version
    // to 16.3 and use `createRef()`.
    // Inline arrow functions is a performance hit
    // so... we use `this`.

    _this.setDateInputRef = function (dateInput) {
      _this._dateInput = dateInput;
    };

    _this.setTimeSelectorRef = function (timeSelector) {
      _this._timeSelector = timeSelector;
    };

    _this.nextValue = function (nextProps, nextState) {
      return nextProps.value !== undefined ? nextProps.value : nextState.value;
    };

    _this.nextShow = function (nextProps, nextState) {
      return nextProps.show !== undefined ? nextProps.show : nextState.show;
    };

    _this.handleInputValueChange = function (event) {
      var value = _this.mergeTime(event.value);

      _this.handleValueChange(__assign({}, event, {
        value: value
      }));
    };

    _this.handleValueChange = function (event) {
      _this.setState({
        value: cloneDate(event.value),
        show: false
      });

      _this.valueDuringOnChange = event.value;
      _this.showDuringOnChange = false;
      _this.shouldFocusDateInput = true;
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange.call(undefined, {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: _this.value,
          show: _this.show,
          target: _this
        });
      }

      _this.valueDuringOnChange = undefined;
      _this.showDuringOnChange = undefined;
    };

    _this.handleFocus = function (event) {
      clearTimeout(_this.nextTickId);

      if (_this._wrapper) {
        _this._wrapper.classList.add('k-state-focused');
      }

      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      _this.nextTick(function () {
        if (_this._wrapper) {
          _this._wrapper.classList.remove('k-state-focused');
        }

        _this.setShow(false);
      });

      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handleValueReject = function (_) {
      _this.setShow(false);
    };

    _this.handleIconClick = function (_) {
      if (_this.props.disabled) {
        return;
      }

      _this.shouldFocusDateInput = true;

      _this.setShow(!_this.show);
    };

    _this.handleIconMouseDown = function (event) {
      event.preventDefault();
    };

    _this.handleKeyDown = function (event) {
      var altKey = event.altKey,
          keyCode = event.keyCode;

      if (keyCode === Keys.esc) {
        _this.setShow(false);

        return;
      }

      if (altKey && (keyCode === Keys.up || keyCode === Keys.down)) {
        event.preventDefault();
        event.stopPropagation();
        _this.shouldFocusDateInput = keyCode === Keys.up;

        _this.setShow(keyCode === Keys.down);
      }
    };

    _this.state = {
      value: _this.props.defaultValue || TimePicker.defaultProps.defaultValue,
      show: _this.props.defaultShow || TimePicker.defaultProps.defaultShow
    };
    _this.normalizeTime = _this.normalizeTime.bind(_this);
    _this.setShow = _this.setShow.bind(_this);
    _this.nextTick = _this.nextTick.bind(_this);
    _this.mergeTime = _this.mergeTime.bind(_this);
    return _this;
  }

  Object.defineProperty(TimePicker.prototype, "element", {
    /**
     * Gets the wrapping element of the TimePicker.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "dateInput", {
    /**
     * Gets the DateInput component inside the TimePicker component.
     */
    get: function get() {
      return this._dateInput;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "timeSelector", {
    /**
     * Gets the TimeSelector component inside the TimePicker component.
     */
    get: function get() {
      return this._timeSelector;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "value", {
    /**
     * Gets the value of the TimePicker.
     */
    get: function get() {
      var value = this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
      return value !== null ? cloneDate(value) : null;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "show", {
    /**
     * Gets the popup state of the TimeSelector.
     */
    get: function get() {
      return this.showDuringOnChange !== undefined ? this.showDuringOnChange : this.props.show !== undefined ? this.props.show : this.state.show;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "name", {
    /**
     * Gets the `name` property of the TimePicker.
     */
    get: function get() {
      return this.props.name;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "validity", {
    /**
     * Represents the validity state into which the TimePicker is set.
     */
    get: function get() {
      var value = this.value && this.normalizeTime(this.value);
      var min = this.normalizeTime(this.min);
      var max = this.normalizeTime(this.max);
      var inRange = isInRange(value, min, max);
      var customError = this.props.validationMessage !== undefined;
      var isValid = (!this.required || this.value !== null) && inRange;
      var valid = this.props.valid !== undefined ? this.props.valid : isValid;
      return {
        customError: customError,
        rangeOverflow: isBiggerThanMax(value, max),
        rangeUnderflow: isSmallerThanMin(value, min),
        valid: valid,
        valueMissing: this.value === null
      };
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "validityStyles", {
    /**
     * @hidden
     */
    get: function get() {
      return this.props.validityStyles !== undefined ? this.props.validityStyles : TimePicker.defaultProps.validityStyles;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "required", {
    /**
     * @hidden
     */
    get: function get() {
      return this.props.required !== undefined ? this.props.required : TimePicker.defaultProps.required;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "popupSettings", {
    get: function get() {
      return this.props.popupSettings || TimePicker.defaultProps.popupSettings;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "min", {
    get: function get() {
      return this.props.min !== undefined ? this.props.min : TimePicker.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimePicker.prototype, "max", {
    get: function get() {
      return this.props.max !== undefined ? this.props.max : TimePicker.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  TimePicker.prototype.componentDidMount = function () {
    if (this.show) {
      // If defaultShow is true during the initial render, the popup is not aligned.
      this.forceUpdate();
    }

    if (this._dateInput && this._dateInput.element) {
      this._dateInput.element.setAttribute('aria-haspopup', 'true');

      this._dateInput.element.setAttribute('aria-expanded', "" + this.show);
    }
  };
  /**
   * @hidden
   */


  TimePicker.prototype.componentWillUnmount = function () {
    clearTimeout(this.nextTickId);
  };
  /**
   * @hidden
   */


  TimePicker.prototype.componentDidUpdate = function (prevProps) {
    if (this._dateInput && this._dateInput.element) {
      this._dateInput.element.setAttribute('aria-expanded', "" + this.show);
    }

    if (this._timeSelector && this.show && !prevProps.show) {
      this._timeSelector.focusActiveList();
    } else if (this._dateInput && this._dateInput.element && !this.show && this.shouldFocusDateInput) {
      this._dateInput.element.focus({
        preventScroll: true
      });
    }

    this.shouldFocusDateInput = false;
  };
  /**
   * @hidden
   */


  TimePicker.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        disabled = _a.disabled,
        tabIndex = _a.tabIndex,
        title = _a.title,
        id = _a.id,
        className = _a.className,
        format = _a.format,
        formatPlaceholder = _a.formatPlaceholder,
        smoothScroll = _a.smoothScroll,
        width = _a.width,
        name = _a.name,
        steps = _a.steps,
        cancelButton = _a.cancelButton,
        nowButton = _a.nowButton,
        validationMessage = _a.validationMessage,
        required = _a.required,
        validityStyles = _a.validityStyles;

    var _b = this.popupSettings,
        popupClass = _b.popupClass,
        otherPopupSettings = __rest(_b, ["popupClass"]);

    var isValid = !this.validityStyles || this.validity.valid;
    var rootClassName = classNames('k-widget k-timepicker', {
      'k-state-invalid': !isValid
    }, className);
    var wrapperClassNames = classNames('k-picker-wrap', {
      'k-state-default': !disabled,
      'k-state-disabled': disabled
    });
    var popupClassNames = classNames('k-group k-reset', popupClass);
    var dateInputProps = {
      disabled: disabled,
      format: format,
      formatPlaceholder: formatPlaceholder,
      id: id,
      max: this.normalizeTime(this.max),
      min: this.normalizeTime(this.min),
      name: name,
      onChange: this.handleInputValueChange,
      required: required,
      steps: steps,
      tabIndex: !this.show ? tabIndex : -1,
      title: title,
      valid: this.validity.valid,
      validationMessage: validationMessage,
      validityStyles: validityStyles,
      value: this.value && this.normalizeTime(this.value)
    };

    var popupProps = __assign({
      show: this.show,
      animate: this._wrapper !== null,
      anchor: this._wrapper || undefined,
      className: popupClassNames,
      id: this._popupId,
      anchorAlign: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      popupAlign: {
        horizontal: 'left',
        vertical: 'top'
      }
    }, otherPopupSettings);

    var timeSelector = React.createElement(TimeSelector, {
      ref: this.setTimeSelectorRef,
      cancelButton: cancelButton,
      disabled: disabled,
      nowButton: nowButton,
      format: format,
      min: this.min,
      max: this.max,
      steps: steps,
      smoothScroll: smoothScroll,
      value: this.value,
      onChange: this.handleValueChange,
      onReject: this.handleValueReject
    });
    var localizationService = provideLocalizationService(this);
    var toggleClockMessage = localizationService.toLanguageString(toggleClock, messages[toggleClock]);
    var toggleTimeMessage = localizationService.toLanguageString(toggleTimeSelector, messages[toggleTimeSelector]);
    return React.createElement("div", {
      ref: function ref(span) {
        _this._element = span;
      },
      className: rootClassName,
      onKeyDown: this.handleKeyDown,
      style: {
        width: width
      },
      onFocus: this.handleFocus,
      onBlur: this.handleBlur
    }, React.createElement("span", {
      ref: function ref(span) {
        _this._wrapper = span;
      },
      className: wrapperClassNames
    }, this.props.dateInput ? React.createElement(this.props.dateInput, __assign({}, dateInputProps)) : React.createElement(DateInput, __assign({
      ref: this.setDateInputRef
    }, dateInputProps)), React.createElement("span", __assign({
      role: "button",
      onMouseDown: this.handleIconMouseDown,
      onClick: this.handleIconClick,
      title: toggleTimeMessage,
      className: "k-select"
    }, {
      'aria-controls': this._popupId,
      'aria-label': toggleClockMessage
    }), React.createElement("span", {
      className: "k-icon k-i-clock"
    }))), this.props.popup ? React.createElement(this.props.popup, __assign({}, popupProps), timeSelector) : React.createElement(Popup, __assign({}, popupProps), timeSelector));
  };

  TimePicker.prototype.normalizeTime = function (date) {
    return setTime(MIDNIGHT_DATE, date);
  };

  TimePicker.prototype.setShow = function (show) {
    if (this.show === show) {
      return;
    }

    this.setState({
      show: show
    });
  };

  TimePicker.prototype.nextTick = function (f) {
    // XXX: use setTimeout due to async focus/blur events in IE, and missing relatedTarget prop.
    // XXX: https://github.com/facebook/react/issues/3751
    clearTimeout(this.nextTickId);
    this.nextTickId = setTimeout(function () {
      return f();
    });
  };

  TimePicker.prototype.mergeTime = function (value) {
    return this.value && value ? setTime(this.value, value) : value;
  };
  /**
   * @hidden
   */


  TimePicker.propTypes = {
    className: PropTypes.string,
    cancelButton: PropTypes.bool,
    nowButton: PropTypes.bool,
    defaultShow: PropTypes.bool,
    defaultValue: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    format: PropTypes.string,
    formatPlaceholder: PropTypes.oneOfType([PropTypes.oneOf(['wide', 'narrow', 'short', 'formatPattern']), PropTypes.shape({
      year: PropTypes.string,
      month: PropTypes.string,
      day: PropTypes.string,
      hour: PropTypes.string,
      minute: PropTypes.string,
      second: PropTypes.string
    })]),
    id: PropTypes.string,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    popupSettings: PropTypes.shape({
      animate: PropTypes.bool,
      appendTo: PropTypes.any,
      popupClass: PropTypes.string
    }),
    show: PropTypes.bool,
    steps: PropTypes.shape({
      hour: PropTypes.number,
      minute: PropTypes.number,
      second: PropTypes.number
    }),
    smoothScroll: PropTypes.bool,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    validationMessage: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.bool,
    valid: PropTypes.bool
  };
  /**
   * @hidden
   */

  TimePicker.defaultProps = {
    defaultShow: false,
    defaultValue: null,
    disabled: false,
    format: 't',
    max: MAX_TIME,
    min: MIN_TIME,
    popupSettings: {},
    title: '',
    tabIndex: 0,
    steps: {},
    required: false,
    validityStyles: true
  };
  return TimePicker;
}(React.Component);

export { TimePicker };
registerForLocalization(TimePicker);