var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import { findDOMNode } from 'react-dom';
/**
 * @hidden
 */

var ToolbarButton =
/** @class */
function (_super) {
  __extends(ToolbarButton, _super);

  function ToolbarButton(props) {
    var _this = _super.call(this, props) || this;

    _this._element = null;
    _this.state = {
      tabIndex: props.tabIndex
    };
    return _this;
  }

  Object.defineProperty(ToolbarButton.prototype, "button", {
    get: function get() {
      var element = findDOMNode(this);

      if (element && element.nodeName !== 'BUTTON') {
        return element.querySelector('button');
      }

      return element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(ToolbarButton.prototype, "tabIndex", {
    get: function get() {
      return this.state.tabIndex;
    },
    set: function set(value) {
      if (this.state.tabIndex !== value) {
        this.setState({
          tabIndex: value
        });
      }
    },
    enumerable: true,
    configurable: true
  });

  ToolbarButton.prototype.focus = function () {
    var button = this.element;

    if (button) {
      button.focus();
    }
  };

  Object.defineProperty(ToolbarButton.prototype, "element", {
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });

  ToolbarButton.prototype.componentDidMount = function () {
    this._element = this.button;
    this.adjustTabIndex();
  };

  ToolbarButton.prototype.componentWillUnmount = function () {
    this._element = null;
  };

  ToolbarButton.prototype.componentDidUpdate = function () {
    this._element = this.button;
    this.adjustTabIndex();
  };

  ToolbarButton.prototype.render = function () {
    var button = this.props.button;
    return React.createElement(button.type, __assign({}, button.props, {
      tabIndex: this.state.tabIndex
    }), button.props.children);
  };

  ToolbarButton.prototype.adjustTabIndex = function () {
    var button = this.element;
    var tabIndex = this.state.tabIndex;

    if (button && button.tabIndex !== tabIndex) {
      button.tabIndex = tabIndex;
    }
  };

  return ToolbarButton;
}(React.Component);

export default ToolbarButton;