var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { cloneDate } from '@progress/kendo-date-math';
import { guid, classNames, Keys } from '@progress/kendo-react-common';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { DateInput } from '../dateinput/DateInput';
import { MultiViewCalendar } from '../calendar/components/MultiViewCalendar';
import { EMPTY_SELECTIONRANGE } from '../calendar/models';
import { MIN_DATE, MAX_DATE } from '../defaults';
import { swapStartEnd, start, end, messages } from '../messages';
var WRAPPER_STYLES = {
  display: 'inline-block'
};

var DateRangePicker =
/** @class */
function (_super) {
  __extends(DateRangePicker, _super);

  function DateRangePicker(props) {
    var _this = _super.call(this, props) || this;

    _this._element = null;
    _this._wrapper = null;
    _this._calendar = null;
    _this._popupId = guid();
    _this._startInputId = guid();
    _this._endInputId = guid();
    _this.shouldFocusDateInput = false;
    _this.shouldFocusCalendar = false;

    _this.setStartDateInputRef = function (dateInput) {
      _this._startDateInput = dateInput;
    };

    _this.setEndDateInputRef = function (dateInput) {
      _this._endDateInput = dateInput;
    };

    _this.setCalendarRef = function (calendar) {
      _this._calendar = calendar;
    };

    _this.focusCalendarElement = function () {
      if (_this._calendar && _this._calendar.element) {
        _this._calendar.element.focus({
          preventScroll: true
        });
      }
    };

    _this.calculateValue = function (props, state) {
      var value = props.value !== undefined ? props.value : state.value;
      return value || EMPTY_SELECTIONRANGE;
    };

    _this.calculateShow = function (nextProps, nextState) {
      return nextProps.show !== undefined ? nextProps.show : nextState.show;
    };

    _this.handleReverseClick = function (event) {
      var value = {
        start: _this.value.end,
        end: _this.value.start
      };
      var args = {
        syntheticEvent: event,
        nativeEvent: event.nativeEvent
      };

      _this.handleChange(value, args);
    };

    _this.handleReverseMouseDown = function (event) {
      event.preventDefault();
    };

    _this.handleFocus = function (event) {
      clearTimeout(_this.nextTickId);

      if (!_this.shouldFocusDateInput) {
        _this.setShow(true);
      }

      if (_this._startDateInput && _this._startDateInput.element) {
        _this._startDateInput.element.classList.add('k-state-focused');
      }

      if (_this._endDateInput && _this._endDateInput.element) {
        _this._endDateInput.element.classList.add('k-state-focused');
      }

      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      _this.nextTick(function () {
        if (_this._startDateInput && _this._startDateInput.element) {
          _this._startDateInput.element.classList.remove('k-state-focused');
        }

        if (_this._endDateInput && _this._endDateInput.element) {
          _this._endDateInput.element.classList.remove('k-state-focused');
        }

        _this.setShow(false);
      });

      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handleEndChange = function (event) {
      var value = {
        start: _this.value.start,
        end: cloneDate(event.value || undefined)
      };

      _this.handleChange(value, event);
    };

    _this.handleStartChange = function (event) {
      var value = {
        start: cloneDate(event.value || undefined),
        end: _this.value.end
      };

      _this.handleChange(value, event);
    };

    _this.extractRangeFromValue = function (event) {
      if (!Array.isArray(event.value) && !(event.value instanceof Date)) {
        return event.value || EMPTY_SELECTIONRANGE;
      }

      var candidate = Array.isArray(event.value) ? event.value[0] : event.value;
      return {
        start: _this.value.end !== null ? candidate : _this.value.start,
        end: _this.value.start !== null ? candidate : _this.value.end
      };
    };

    _this.handleCalendarChange = function (event) {
      var value = _this.extractRangeFromValue(event);

      _this.handleChange(value, event);
    };

    _this.handleKeyDown = function (event) {
      var keyCode = event.keyCode,
          altKey = event.altKey;

      if (keyCode === Keys.esc) {
        event.preventDefault();
        _this.shouldFocusDateInput = true;

        _this.setShow(false);
      } else if (altKey && keyCode === Keys.down) {
        event.preventDefault();
        _this.shouldFocusCalendar = true;

        _this.setShow(true);
      } else if (keyCode === Keys.tab && _this.show && _this._calendar && _this._calendar.element && _this._endDateInput && _this._endDateInput.element && document && document.activeElement === _this._endDateInput.element) {
        event.preventDefault();

        _this.focusCalendarElement();
      }
    };

    _this.handleChange = function (value, event) {
      _this.setState({
        value: value
      });

      _this.valueDuringOnChange = value;
      var onChange = _this.props.onChange;

      if (onChange) {
        var args = {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: _this.value,
          show: _this.show,
          target: _this
        };
        onChange.call(undefined, args);
      }

      _this.valueDuringOnChange = undefined;
    };

    _this.state = {
      show: _this.props.show || _this.props.defaultShow || DateRangePicker.defaultProps.defaultShow,
      value: _this.props.value || _this.props.defaultValue || DateRangePicker.defaultProps.defaultValue
    };
    _this.nextTick = _this.nextTick.bind(_this);
    _this.setShow = _this.setShow.bind(_this);
    _this.focusCalendarElement = _this.focusCalendarElement.bind(_this);
    _this.focusDateInputElement = _this.focusDateInputElement.bind(_this);
    return _this;
  }

  Object.defineProperty(DateRangePicker.prototype, "element", {
    /**
     * Gets the wrapping element of the DateRangePicker.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateRangePicker.prototype, "calendar", {
    /**
     * Gets the MultiVieCalendar inside the DateRangePicker.
     */
    get: function get() {
      return this._calendar;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateRangePicker.prototype, "value", {
    /**
     * Gets the value of the DateRangePicker.
     */
    get: function get() {
      var value = this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
      return value || EMPTY_SELECTIONRANGE;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateRangePicker.prototype, "show", {
    /**
     * Gets the popup state of the DateRangePicker.
     */
    get: function get() {
      return this.showDuringOnChange !== undefined ? this.showDuringOnChange : this.props.show !== undefined ? this.props.show : this.state.show;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateRangePicker.prototype, "min", {
    get: function get() {
      return this.props.min !== undefined ? this.props.min : DateRangePicker.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(DateRangePicker.prototype, "max", {
    get: function get() {
      return this.props.max !== undefined ? this.props.max : DateRangePicker.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  DateRangePicker.prototype.componentDidMount = function () {
    if (this.show) {
      // If defaultShow is true during the initial render, the popup is not aligned.
      this.forceUpdate();
    }

    if (this._startDateInput && this._startDateInput.element && this._endDateInput && this._endDateInput.element) {
      this._startDateInput.element.setAttribute('aria-expanded', "" + this.show);

      this._endDateInput.element.setAttribute('aria-expanded', "" + this.show);
    }
  };
  /**
   * @hidden
   */


  DateRangePicker.prototype.componentDidUpdate = function () {
    if (this._startDateInput && this._startDateInput.element && this._endDateInput && this._endDateInput.element) {
      this._startDateInput.element.setAttribute('aria-expanded', "" + this.show);

      this._endDateInput.element.setAttribute('aria-expanded', "" + this.show);
    }

    if (this.shouldFocusCalendar) {
      this.focusCalendarElement();
    }

    if (this.shouldFocusDateInput) {
      this.focusDateInputElement();
    }

    this.shouldFocusCalendar = false;
    this.shouldFocusDateInput = false;
  };
  /**
   * @hidden
   */


  DateRangePicker.prototype.componentWillUnmount = function () {
    clearTimeout(this.nextTickId);
  };
  /**
   * @hidden
   */


  DateRangePicker.prototype.render = function () {
    var _this = this;

    var value = this.value || EMPTY_SELECTIONRANGE;
    var startDateInputId = (this.props.startDateInputSettings || {}).id || this._startInputId;
    var endDateInputId = (this.props.endDateInputSettings || {}).id || this._endInputId;
    var rootClassName = classNames('k-daterangepicker', {
      'k-state-disabled': this.props.disabled
    }, this.props.className);
    var localizationService = provideLocalizationService(this);
    var startMessage = localizationService.toLanguageString(start, messages[start]);
    var endMessage = localizationService.toLanguageString(end, messages[end]);

    var startDateInputProps = __assign({
      label: startMessage,
      format: this.props.format,
      min: this.min,
      max: this.max,
      id: this._startInputId,
      disabled: this.props.disabled
    }, this.props.startDateInputSettings, {
      value: value.start,
      onChange: this.handleStartChange
    });

    var endDateInputProps = __assign({
      label: endMessage,
      format: this.props.format,
      min: this.min,
      max: this.max,
      id: this._endInputId,
      disabled: this.props.disabled
    }, this.props.endDateInputSettings, {
      value: value.end,
      onChange: this.handleEndChange
    });

    var popupProps = __assign({
      animate: this._wrapper !== null,
      anchor: this._wrapper || undefined,
      id: this._popupId,
      anchorAlign: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      popupAlign: {
        horizontal: 'left',
        vertical: 'top'
      }
    }, this.props.popupSettings, {
      show: this.show
    });

    var calendarProps = __assign({
      min: this.min,
      max: this.max,
      allowReverse: this.props.allowReverse,
      mode: 'range',
      focusedDate: this.props.focusedDate,
      disabled: this.props.disabled
    }, this.props.calendarSettings, {
      value: value,
      onChange: this.handleCalendarChange
    });

    var calendar = this.props.calendar ? React.createElement(this.props.calendar, __assign({}, calendarProps)) : React.createElement(MultiViewCalendar, __assign({}, calendarProps, {
      ref: this.setCalendarRef
    }));
    return React.createElement("span", {
      ref: function ref(span) {
        _this._element = span;
      },
      className: rootClassName,
      style: this.props.style,
      id: this.props.id,
      tabIndex: this.props.tabIndex,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onKeyDown: this.handleKeyDown
    }, React.createElement("span", {
      ref: function ref(span) {
        _this._wrapper = span;
      },
      className: "k-daterangepicker-wrap",
      style: WRAPPER_STYLES
    }, this.props.startDateInput ? React.createElement(this.props.startDateInput, __assign({}, startDateInputProps)) : React.createElement(DateInput, __assign({}, startDateInputProps, {
      ref: this.setStartDateInputRef
    })), (this.props.allowReverse || this.props.calendarSettings && this.props.calendarSettings.allowReverse) && this.props.swapButton ? React.createElement("span", __assign({
      role: "button",
      className: "k-select k-button k-flat",
      title: provideLocalizationService(this).toLanguageString(swapStartEnd, messages[swapStartEnd]),
      onMouseDown: this.handleReverseMouseDown,
      onClick: this.handleReverseClick
    }, {
      'aria-controls': startDateInputId + ' ' + endDateInputId,
      'aria-label': provideLocalizationService(this).toLanguageString(swapStartEnd, messages[swapStartEnd])
    }), React.createElement("span", {
      style: {
        transform: 'rotate(90deg)'
      },
      className: "k-icon k-i-arrows-swap"
    })) // tslint:disable-next-line jsx-use-translation-function
    : React.createElement("span", null, "\xA0"), this.props.endDateInput ? React.createElement(this.props.endDateInput, __assign({}, endDateInputProps)) : React.createElement(DateInput, __assign({}, endDateInputProps, {
      ref: this.setEndDateInputRef
    }))), this.props.popup ? React.createElement(this.props.popup, __assign({}, popupProps), calendar) : React.createElement(Popup, __assign({}, popupProps), calendar));
  };

  DateRangePicker.prototype.focusDateInputElement = function () {
    if (!document || !this._startDateInput || !this._startDateInput.element || !this._endDateInput || !this._endDateInput.element) {
      return;
    }

    if ((this.value.start === null || this.value.end !== null) && document.activeElement !== this._endDateInput.element) {
      this._startDateInput.element.focus({
        preventScroll: true
      });
    } else if (document.activeElement !== this._startDateInput.element) {
      this._endDateInput.element.focus({
        preventScroll: true
      });
    }
  };

  DateRangePicker.prototype.nextTick = function (f) {
    // XXX: use setTimeout due to async focus/blur events in IE, and missing relatedTarget prop.
    // XXX: https://github.com/facebook/react/issues/3751
    // Handles multiple focus events happening at the same time.
    clearTimeout(this.nextTickId);
    this.nextTickId = setTimeout(function () {
      return f();
    });
  };

  DateRangePicker.prototype.setShow = function (show) {
    if (this.show === show) {
      return;
    }

    this.setState({
      show: show
    });
  };
  /**
   * @hidden
   */


  DateRangePicker.propTypes = {
    allowReverse: PropTypes.bool,
    calendarSettings: PropTypes.any,
    className: PropTypes.string,
    defaultShow: PropTypes.bool,
    defaultValue: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date)
    }),
    disabled: PropTypes.bool,
    endDateInputSettings: PropTypes.shape(DateInput.propTypes),
    focusedDate: PropTypes.instanceOf(Date),
    format: PropTypes.string,
    id: PropTypes.string,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    popupSettings: PropTypes.any,
    show: PropTypes.bool,
    startDateInputSettings: PropTypes.any,
    style: PropTypes.any,
    swapButton: PropTypes.any,
    tabIndex: PropTypes.number,
    value: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date)
    })
  };
  /**
   * @hidden
   */

  DateRangePicker.defaultProps = {
    allowReverse: false,
    defaultShow: false,
    defaultValue: EMPTY_SELECTIONRANGE,
    disabled: false,
    format: 'd',
    max: MAX_DATE,
    min: MIN_DATE,
    swapButton: false
  };
  return DateRangePicker;
}(React.Component);

export { DateRangePicker };
registerForLocalization(DateRangePicker);