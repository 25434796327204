var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import * as ReactDOMServer from 'react-dom/server';
import { Slide } from '@progress/kendo-react-animation';
import { classNames } from '@progress/kendo-react-common';
import { CollisionType, AlignPoint, throttle, FRAME_DURATION, isWindowAvailable } from './util';
import { AlignService } from './services/alignService';
import { DOMService } from './services/domService';
import { PositionService } from './services/positionService';
var DEFAULT_OFFSET = {
  left: -1000,
  top: 0
};
var ANIMATION_CONTAINER = 'k-animation-container';
var ANIMATION_CONTAINER_SHOWN = 'k-animation-container-shown';
var K_POPUP = 'k-popup';

var getHtmlElement = function getHtmlElement(source) {
  return ReactDOM.findDOMNode(source);
};

var Popup =
/** @class */
function (_super) {
  __extends(Popup, _super);

  function Popup(props) {
    var _this = _super.call(this, props) || this;

    _this._exitingAnimation = false;
    _this._prevShow = false;

    _this.onOpened = function () {
      var element = getHtmlElement(_this);

      if (_this.props.show) {
        element.classList.add(ANIMATION_CONTAINER_SHOWN);
      }

      _this.attachRepositionHandlers(element);

      if (_this.props.open) {
        _this.props.open.call(undefined, {
          target: _this
        });
      }
    };

    _this.onClosing = function () {
      if (!_this.props.show) {
        var element = getHtmlElement(_this);
        element.classList.remove(ANIMATION_CONTAINER_SHOWN);
      }

      _this.detachRepositionHandlers();
    };

    _this.onClosed = function () {
      if (_this._exitingAnimation) {
        _this._exitingAnimation = false;

        _this.forceUpdate();
      }

      if (_this.props.close) {
        _this.props.close.call(undefined, {
          target: _this
        });
      }
    };

    _this.position = function (settings, element, anchorElement) {
      var anchorAlign = settings.anchorAlign,
          popupAlign = settings.popupAlign,
          collision = settings.collision,
          offset = settings.offset;

      var alignedOffset = _this._alignService.alignElement({
        anchor: anchorElement,
        element: element,
        elementAlign: popupAlign,
        anchorAlign: anchorAlign,
        offset: offset
      });

      var result = _this._positionService.positionElement({
        anchor: anchorElement,
        anchorAlign: anchorAlign,
        collisions: collision,
        element: element,
        currentLocation: alignedOffset,
        elementAlign: popupAlign
      });

      return result;
    };

    _this._flipped = false;
    _this._offset = _this.props.offset;
    _this._prevShow = props.show;
    _this._domService = new DOMService();
    _this._alignService = new AlignService(_this._domService);
    _this._positionService = new PositionService(_this._domService);
    _this.onOpened = _this.onOpened.bind(_this);
    _this.onClosing = _this.onClosing.bind(_this);
    _this.reposition = throttle(_this.reposition.bind(_this), FRAME_DURATION);
    return _this;
  }
  /**
   * @hidden
   */


  Popup.prototype.componentDidUpdate = function () {
    this._prevShow = this.props.show;
  };
  /**
   * @hidden
   */


  Popup.prototype.componentWillUnmount = function () {
    this.detachRepositionHandlers();
  };
  /**
   * @hidden
   */


  Popup.prototype.render = function () {
    var _a = this.props,
        children = _a.children,
        className = _a.className,
        popupClass = _a.popupClass,
        show = _a.show,
        id = _a.id,
        _b = _a.appendTo,
        appendTo = _b === void 0 ? isWindowAvailable() ? document.body : undefined : _b;

    if (show) {
      var newPosition = this.calculatePosition(this.props, appendTo);
      this._offset = newPosition.offset;
      this._flipped = !!newPosition.flipped;
    }

    var direction = this._flipped && show ? 'up' : 'down';
    var _c = this.transitionDuration,
        transitionEnterDuration = _c.transitionEnterDuration,
        transitionExitDuration = _c.transitionExitDuration;
    var style = Object.assign({}, {
      position: 'absolute'
    }, this.props.style || {}, __assign({}, this._offset));
    this._exitingAnimation = this._exitingAnimation || this._prevShow && !show;

    if ((show || this._exitingAnimation) && appendTo) {
      var popup = React.createElement(Slide, {
        componentChildClassName: classNames(popupClass, K_POPUP),
        className: classNames(className),
        id: id,
        onEntered: this.onOpened,
        onExiting: this.onClosing,
        onExited: this.onClosed,
        direction: direction,
        style: style,
        transitionEnterDuration: transitionEnterDuration,
        transitionExitDuration: transitionExitDuration,
        appear: true
      }, show ? children : null);
      return ReactDOM.createPortal(popup, appendTo);
    }

    return null;
  };

  Object.defineProperty(Popup.prototype, "transitionDuration", {
    get: function get() {
      var animate = this.props.animate;
      var transitionEnterDuration = 0;
      var transitionExitDuration = 0;

      if (animate) {
        if (animate === true) {
          // Inherit the default duration of the Animation component.
          transitionEnterDuration = transitionExitDuration = undefined;
        } else {
          transitionEnterDuration = animate.openDuration;
          transitionExitDuration = animate.closeDuration;
        }
      }

      return {
        transitionEnterDuration: transitionEnterDuration,
        transitionExitDuration: transitionExitDuration
      };
    },
    enumerable: true,
    configurable: true
  });

  Popup.prototype.calculatePosition = function (props, appendTo) {
    if (!appendTo || !isWindowAvailable()) {
      return {
        flipped: false,
        offset: props.offset
      };
    }

    var root = document.createElement('div');
    appendTo.appendChild(root);
    var style = Object.assign({}, props.style || {}, __assign({
      visibility: 'hidden'
    }, DEFAULT_OFFSET));
    var innerClasses = {
      className: classNames(props.popupClass, K_POPUP, 'k-child-animation-container')
    };
    var popup = React.createElement("div", {
      className: classNames(ANIMATION_CONTAINER, ANIMATION_CONTAINER_SHOWN, props.className),
      style: style
    }, React.Children.map(props.children, function (child, index) {
      return React.createElement("div", __assign({
        key: index
      }, innerClasses), child);
    }));
    root.innerHTML = ReactDOMServer.renderToStaticMarkup(popup);
    var newPosition = this.position(props, root.firstChild, props.anchor);
    root.parentNode.removeChild(root);
    return newPosition;
  };

  Popup.prototype.attachRepositionHandlers = function (element) {
    var _this = this;

    this.detachRepositionHandlers();
    this._scrollableParents = this._domService.scrollableParents(this.props.anchor || element);

    this._scrollableParents.map(function (p) {
      return p.addEventListener('scroll', _this.reposition);
    });

    window.addEventListener('resize', this.reposition);
  };

  Popup.prototype.detachRepositionHandlers = function () {
    var _this = this;

    if (this._scrollableParents) {
      this._scrollableParents.map(function (p) {
        return p.removeEventListener('scroll', _this.reposition);
      });

      this._scrollableParents = undefined;
    }

    window.removeEventListener('resize', this.reposition);
  };

  Popup.prototype.reposition = function () {
    this.forceUpdate();
  };
  /**
   * @hidden
   */


  Popup.propTypes = {
    anchor: function anchor(props) {
      var anchor = props.anchor;

      if (anchor && typeof anchor.nodeType !== 'number') {
        return new Error('Invalid prop `anchor` supplied to `Kendo React Popup`. Validation failed.');
      }
    },
    appendTo: function appendTo(props) {
      var element = props.appendTo;

      if (element && typeof element.nodeType !== 'number') {
        return new Error('Invalid prop `appendTo` supplied to `Kendo React Popup`. Validation failed.');
      }
    },
    className: PropTypes.string,
    id: PropTypes.string,
    popupClass: PropTypes.string,
    collision: PropTypes.shape({
      horizontal: PropTypes.oneOf([CollisionType.fit, CollisionType.flip]),
      vertical: PropTypes.oneOf([CollisionType.fit, CollisionType.flip])
    }),
    anchorAlign: PropTypes.shape({
      horizontal: PropTypes.oneOf([AlignPoint.left, AlignPoint.center, AlignPoint.right]),
      vertical: PropTypes.oneOf([AlignPoint.top, AlignPoint.center, AlignPoint.bottom])
    }),
    popupAlign: PropTypes.shape({
      horizontal: PropTypes.oneOf([AlignPoint.left, AlignPoint.center, AlignPoint.right]),
      vertical: PropTypes.oneOf([AlignPoint.top, AlignPoint.center, AlignPoint.bottom])
    }),
    offset: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number
    }),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    show: PropTypes.bool,
    animate: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
      openDuration: PropTypes.number,
      closeDuration: PropTypes.number
    })])
  };
  /**
   * @hidden
   */

  Popup.defaultProps = {
    collision: {
      horizontal: CollisionType.fit,
      vertical: CollisionType.flip
    },
    anchorAlign: {
      horizontal: AlignPoint.left,
      vertical: AlignPoint.bottom
    },
    popupAlign: {
      horizontal: AlignPoint.left,
      vertical: AlignPoint.top
    },
    offset: DEFAULT_OFFSET,
    animate: true,
    show: false
  };
  return Popup;
}(React.Component);

export default Popup;