var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { registerForIntl, provideIntlService, registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { classNames, guid, Keys } from '@progress/kendo-react-common';
import { cloneDate, isEqualDate, getDate } from '@progress/kendo-date-math';
import { Action, CalendarViewEnum, EMPTY_SELECTIONRANGE } from '../models';
import { MIN_DATE, MAX_DATE } from '../../defaults';
import { messages, prevView, nextView } from '../../messages';
import { BusViewService, NavigationService } from '../services';
import { dateInRange, isInRange, viewInRange, getToday } from '../../utils';
import { HorizontalViewList } from './HorizontalViewList';

var extractDateFromValue = function extractDateFromValue(min, max, value) {
  if (min === void 0) {
    min = MultiViewCalendar.defaultProps.min;
  }

  if (max === void 0) {
    max = MultiViewCalendar.defaultProps.max;
  }

  return value instanceof Date && !Array.isArray(value) && isInRange(getDate(value), min, max) ? getDate(value) : null;
};

var extractMultipleFromValue = function extractMultipleFromValue(min, max, value) {
  if (min === void 0) {
    min = MultiViewCalendar.defaultProps.min;
  }

  if (max === void 0) {
    max = MultiViewCalendar.defaultProps.max;
  }

  return Array.isArray(value) ? value.filter(function (date) {
    return isInRange(date, min, max);
  }).map(function (date) {
    return getDate(date);
  }) : null;
};

var extractRangeFromValue = function extractRangeFromValue(value) {
  return typeof value === 'object' && !(value instanceof Date) && value !== null && !Array.isArray(value) ? value : EMPTY_SELECTIONRANGE;
};

var extractFocusedDate = function extractFocusedDate(single, multiple, range) {
  return single || multiple && multiple[0] || range && range.start;
};

var extractActiveRange = function extractActiveRange(range, single) {
  return range.start === null && single === null ? 'start' : range.end === null ? 'end' : 'start';
};

var MultiViewCalendar =
/** @class */
function (_super) {
  __extends(MultiViewCalendar, _super);

  function MultiViewCalendar(props) {
    var _this = _super.call(this, props) || this;

    _this.selectedDate = null;
    _this.selectedMultiple = null;
    _this.selectedRange = EMPTY_SELECTIONRANGE;
    _this.cellUID = guid();
    _this.activeRangeEnd = 'start';
    _this.wrapperID = guid();
    _this.isActive = false;
    _this.calculateFocusFromValue = true;

    _this.clampRange = function (value) {
      return {
        start: value,
        end: null
      };
    };

    _this.rangeWithFocused = function (range, focusedDate) {
      return {
        start: range.start,
        end: range.end === null && range.start !== null && _this.isActive ? focusedDate : range.end
      };
    };

    _this.generateRange = function (candidate, value) {
      var end = value.end,
          start = value.start;
      var shouldSwap = value.start !== null && candidate.getTime() <= value.start.getTime();

      if (!_this.props.allowReverse && shouldSwap) {
        return {
          start: candidate,
          end: _this.selectedRange.start
        };
      }

      return _this.activeRange !== 'end' ? {
        start: candidate,
        end: end
      } : {
        start: start || _this.selectedDate,
        end: candidate
      };
    };

    _this.canNavigate = function (action) {
      if (!_this.service) {
        return false;
      }

      var candidate = _this.service.move(_this.focusedDate, action);

      return _this.min <= candidate && candidate <= _this.max || _this.service.isInSameView(candidate, _this.min) || _this.service.isInSameView(candidate, _this.max);
    };

    _this.isListInRange = function (list) {
      return _this.min < list[0] && _this.max > list[Math.max(0, (_this.props.views || MultiViewCalendar.defaultProps.views) - 1)];
    };

    _this.navigate = function (action) {
      _this.calculateFocusFromValue = false;

      var candidate = _this.move(action);

      _this.setState({
        focusedDate: candidate
      });
    };

    _this.move = function (action) {
      return _this.clampDate(_this.service.move(_this.focusedDate, action));
    };

    _this.clampDate = function (value) {
      return dateInRange(value, _this.min, _this.max);
    };

    _this.shouldAutoCorrect = function (candidate, value) {
      var end = value.end,
          start = value.start;

      if (_this.activeRange !== 'end') {
        return end !== null && candidate > end;
      } else {
        return start !== null && candidate < start;
      }
    };

    _this.handleCellEnter = function (value) {
      if (_this.props.mode === 'range') {
        _this.calculateFocusFromValue = false;

        _this.setState({
          focusedDate: value
        });
      }
    };

    _this.handleMouseDown = function (event) {
      event.preventDefault();
    };

    _this.handleClick = function (_) {
      if (!_this._element) {
        return;
      }

      _this._element.focus({
        preventScroll: true
      });
    };

    _this.handleFocus = function (event) {
      _this.isActive = true;

      if (!_this.calendarViewList) {
        return;
      }

      _this.calendarViewList.focusActiveDate();

      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      _this.isActive = false;

      if (!_this.calendarViewList) {
        return;
      }

      _this.calendarViewList.blurActiveDate();

      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handlePrevButtonClick = function () {
      _this.navigate(Action.PrevView);
    };

    _this.handleNextButtonClick = function () {
      _this.navigate(Action.NextView);
    };

    _this.handleKeyDown = function (event) {
      var keyCode = event.keyCode;

      if (keyCode === Keys.enter) {
        var args = {
          syntheticEvent: event,
          nativeEvent: event.nativeEvent,
          value: _this.focusedDate,
          target: _this
        };

        _this.handleDateChange.call(undefined, args);
      } else {
        var candidate = dateInRange(_this.navigation.move(_this.focusedDate, _this.navigation.action(event), _this.state.activeView, _this.service, event), _this.min, _this.max);

        if (isEqualDate(_this.focusedDate, candidate)) {
          return;
        }

        _this.calculateFocusFromValue = false;

        _this.setState({
          focusedDate: candidate
        });
      }

      event.preventDefault();
    };

    _this.handleViewChange = function (_a) {
      var view = _a.view;

      _this.setState({
        activeView: view
      });
    };

    _this.handleDateChange = function (event) {
      _this.calculateFocusFromValue = true;
      var focusedDate = cloneDate(event.value);

      var canNavigateDown = _this.bus.canMoveDown(_this.state.activeView);

      if (_this.props.disabled) {
        return;
      }

      if (canNavigateDown) {
        if (event.isTodayClick) {
          _this.bus.moveToBottom(_this.state.activeView);
        } else {
          _this.bus.moveDown(_this.state.activeView, event.syntheticEvent);

          _this.setState({
            focusedDate: focusedDate
          });

          return;
        }
      }

      var value;

      switch (_this.props.mode) {
        case 'single':
          value = cloneDate(event.value);
          break;

        case 'multiple':
          if (Array.isArray(_this.selectedMultiple)) {
            var result = _this.selectedMultiple.slice();

            var index_1 = -1;
            result.forEach(function (date, idx) {
              if (isEqualDate(date, event.value)) {
                index_1 = idx;
              }
            });
            index_1 !== -1 ? result.splice(index_1, 1) : result.push(cloneDate(event.value));
            value = result.slice();
          } else {
            if (_this.selectedDate) {
              value = [cloneDate(_this.selectedDate), cloneDate(event.value)];
            } else {
              value = [cloneDate(event.value)];
            }
          }

          break;

        case 'range':
          var hasSelection = _this.selectedRange.start !== null && _this.selectedRange.end !== null && _this.activeRange === 'start';
          value = hasSelection ? _this.clampRange(event.value) : _this.generateRange(event.value, _this.selectedRange);
          _this.activeRangeEnd = _this.activeRange !== 'end' ? 'end' : 'start';
          break;

        default:
          value = cloneDate(event.value);
          break;
      }

      _this.valueDuringOnChange = value;

      _this.setState({
        value: value,
        focusedDate: focusedDate
      });

      _this.valueDuringOnChange = value;
      var onChange = _this.props.onChange;

      if (onChange) {
        var args = {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: value,
          target: _this
        };
        onChange.call(undefined, args);
      }

      _this.valueDuringOnChange = undefined;
    };

    var value = props.value !== undefined ? props.value : props.defaultValue || MultiViewCalendar.defaultProps.defaultValue;
    var selectedDate = extractDateFromValue(_this.min, _this.max, value);
    var selectedMultiple = extractMultipleFromValue(_this.min, _this.max, value);
    var selectedRange = extractRangeFromValue(value);
    var calculatedFocus = extractFocusedDate(selectedDate, selectedMultiple, selectedRange);
    var activeView = viewInRange(CalendarViewEnum[props.defaultActiveView], _this.bottomView, _this.topView);
    var focusedDate = dateInRange(props.focusedDate || calculatedFocus || getToday(), _this.min, _this.max);
    _this.state = {
      value: value,
      activeView: activeView,
      focusedDate: focusedDate
    };
    _this.activeRangeEnd = extractActiveRange(selectedRange, selectedDate);
    _this.bus = new BusViewService(_this.handleViewChange);
    _this.navigation = new NavigationService(_this.bus);
    _this.calculateFocusFromValue = false;
    return _this;
  }

  Object.defineProperty(MultiViewCalendar.prototype, "element", {
    /**
     * Gets the wrapping element of the MultiViewCalendar component.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "value", {
    /**
     * Gets the value of the MultiViewCalendar.
     */
    get: function get() {
      return this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "focusedDate", {
    /**
     * Gets the current focused date of the MultiViewCalendar.
     */
    get: function get() {
      return cloneDate(this._focusedDate);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "min", {
    get: function get() {
      return getDate(this.props.min !== undefined ? this.props.min : MultiViewCalendar.defaultProps.min);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "max", {
    get: function get() {
      return getDate(this.props.max !== undefined ? this.props.max : MultiViewCalendar.defaultProps.max);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "bottomView", {
    get: function get() {
      return CalendarViewEnum[this.props.bottomView !== undefined ? this.props.bottomView : MultiViewCalendar.defaultProps.bottomView];
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "topView", {
    get: function get() {
      return CalendarViewEnum[this.props.topView !== undefined ? this.props.topView : MultiViewCalendar.defaultProps.topView];
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(MultiViewCalendar.prototype, "activeRange", {
    get: function get() {
      return this.props.activeRangeEnd !== undefined ? this.props.activeRangeEnd : this.activeRangeEnd;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  MultiViewCalendar.prototype.componentDidMount = function () {
    this.calculateFocusFromValue = true;
  };
  /**
   * @hidden
   */


  MultiViewCalendar.prototype.componentDidUpdate = function () {
    if (this.calendarViewList) {
      this.isActive ? this.calendarViewList.focusActiveDate() : this.calendarViewList.blurActiveDate();
    }

    this.calculateFocusFromValue = false;
  };
  /**
   * @hidden
   */


  MultiViewCalendar.prototype.render = function () {
    var _this = this;

    this.intl = provideIntlService(this);
    this.localization = provideLocalizationService(this);
    this.bus.configure(this.bottomView, this.topView);
    var activeView = viewInRange(this.state.activeView, this.bottomView, this.topView);
    this.service = this.bus.service(activeView, this.intl);
    this.selectedDate = extractDateFromValue(this.min, this.max, this.value);
    this.selectedMultiple = extractMultipleFromValue(this.min, this.max, this.value);
    this.selectedRange = extractRangeFromValue(this.value);
    var calculatedFocus = extractFocusedDate(this.selectedDate, this.selectedMultiple, this.selectedRange);
    this._focusedDate = dateInRange(this.calculateFocusFromValue && calculatedFocus !== null ? calculatedFocus : this.state.focusedDate, this.min, this.max);
    var wrapperClassName = classNames('k-widget k-calendar k-calendar-infinite k-calendar-range', {
      'k-state-disabled': this.props.disabled
    }, this.props.className);
    var visualizedRange = this.rangeWithFocused(this.selectedRange, this.focusedDate);
    var wrapperAria = {
      'aria-disabled': this.props.disabled,
      'aria-activedescendant': this.cellUID + this.focusedDate.getTime()
    };
    var prevViewTitle = this.localization.toLanguageString(prevView, messages[prevView]);
    var nextViewTittle = this.localization.toLanguageString(nextView, messages[nextView]);
    var isPrevDisabled = !this.canNavigate(Action.PrevView);
    var isNextDisabled = !this.canNavigate(Action.NextView);
    var prevBtnAria = {
      'aria-disabled': isPrevDisabled
    };
    var nextBtnAria = {
      'aria-disabled': isNextDisabled
    };
    return React.createElement("div", __assign({
      ref: function ref(el) {
        _this._element = el;
      },
      className: wrapperClassName,
      id: this.props.id || this.wrapperID,
      role: 'grid',
      tabIndex: !this.props.disabled ? this.props.tabIndex : undefined,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onMouseDown: this.handleMouseDown,
      onClick: this.handleClick,
      onKeyDown: this.handleKeyDown
    }, wrapperAria), React.createElement("button", __assign({
      className: "k-button k-prev-view",
      title: prevViewTitle,
      disabled: isPrevDisabled,
      onClick: this.handlePrevButtonClick
    }, prevBtnAria), React.createElement("span", {
      className: "k-icon k-i-arrow-chevron-left"
    })), React.createElement(HorizontalViewList, {
      ref: function ref(el) {
        _this.calendarViewList = el;
      },
      activeView: activeView,
      focusedDate: this.focusedDate,
      min: this.min,
      max: this.max,
      bus: this.bus,
      service: this.service,
      selectionRange: visualizedRange,
      value: this.selectedMultiple || this.selectedDate,
      cellUID: this.cellUID,
      views: this.props.views,
      onChange: this.handleDateChange,
      showWeekNumbers: this.props.weekNumber,
      onCellEnter: this.handleCellEnter,
      cell: this.props.cell,
      weekCell: this.props.weekCell,
      headerTitle: this.props.headerTitle
    }), React.createElement("button", __assign({
      className: "k-button k-next-view",
      title: nextViewTittle,
      disabled: isNextDisabled,
      onClick: this.handleNextButtonClick
    }, nextBtnAria), React.createElement("span", {
      className: "k-icon k-i-arrow-chevron-right"
    })));
  };
  /**
   * @hidden
   */


  MultiViewCalendar.propTypes = {
    activeRangeEnd: PropTypes.oneOf(['start', 'end']),
    allowReverse: PropTypes.bool,
    bottomView: PropTypes.oneOf(['month', 'year', 'decade', 'century']),
    className: PropTypes.string,
    defaultActiveView: PropTypes.oneOf(['month', 'year', 'decade', 'century']),
    defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date)), PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date)
    })]),
    disabled: PropTypes.bool,
    focusedDate: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    mode: PropTypes.oneOf(['single', 'multiple', 'range']),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    tabIndex: PropTypes.number,
    topView: PropTypes.oneOf(['month', 'year', 'decade', 'century']),
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date)), PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date)
    })]),
    views: function views(props, propName, componentName) {
      var views = props[propName];

      if (views !== undefined && views < 1) {
        return new Error("Invalid prop '" + propName + "' supplied to" + ("'" + componentName + "'. The '" + propName + "' property cannot be less than 1'"));
      }
    },
    weekNumber: PropTypes.bool
  };
  /**
   * @hidden
   */

  MultiViewCalendar.defaultProps = {
    disabled: false,
    min: MIN_DATE,
    max: MAX_DATE,
    navigation: true,
    defaultActiveView: 'month',
    defaultValue: null,
    topView: 'century',
    tabIndex: 0,
    bottomView: 'month',
    views: 2,
    allowReverse: false
  };
  return MultiViewCalendar;
}(React.Component);

export { MultiViewCalendar };
registerForIntl(MultiViewCalendar);
registerForLocalization(MultiViewCalendar);